import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

/**
 * 初期型
 */
export interface ABTestState {
  ruijiRecommendGroupNo: number;
  pcTopIntroButtonGroupNo?: string | null;
}

/**
 * Slice型
 */
export interface ABTestSliceState {
  abTest: ReturnType<typeof abTestReducer>;
}

/**
 * 初期状態
 */
export const abTestInitialState: ABTestState = {
  ruijiRecommendGroupNo: 1,
  pcTopIntroButtonGroupNo: '0',
};

/**
 * slice
 */
const abTestSlice = createSlice({
  name: 'abTest',
  initialState: abTestInitialState,
  reducers: {
    setRuijiRecommendGroupNo: (state, action: PayloadAction<number>) => {
      state.ruijiRecommendGroupNo = action.payload;
    },
    setPcTopIntroButtonGroupNo: (
      state,
      action: PayloadAction<ABTestState['pcTopIntroButtonGroupNo']>
    ) => {
      state.pcTopIntroButtonGroupNo = action.payload;
    },
  },
});

export const { setRuijiRecommendGroupNo, setPcTopIntroButtonGroupNo } =
  abTestSlice.actions;

export const abTestReducer = abTestSlice.reducer;

/**
 * Selector ruijiRecommendGroupNoSelector
 * @param state
 */
export const ruijiRecommendGroupNoSelector = (state: RootState) =>
  state.abTest.ruijiRecommendGroupNo;

/**
 * Selector pcTopIntroButtonGroupNoSelector
 * @param state
 * @returns pcTopIntroButtonGroupNo
 */
export const pcTopIntroButtonGroupNoSelector = (state: RootState) =>
  state.abTest.pcTopIntroButtonGroupNo;
