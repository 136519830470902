import { tl } from '@doda/common/src/utils/adobeAnalyticsUtil';

/**
 * DODASITE-5481_PC TOP_はじめての方へボタンデザイン変更ABテスト imp計測
 * @param groupNo ABテストグループNo
 */
export const measurePcTopIntroButton = (groupNo: string | null | undefined) => {
  let abTest = '';
  if (groupNo === '1') {
    // オリジナルパターン
    abTest = 'A';
  } else if (groupNo === '2') {
    // テスト1パターン
    abTest = 'B';
  } else if (groupNo === '3') {
    // テスト2パターン
    abTest = 'C';
  }

  const linkName = `top_introbutton_nonmember_${abTest}_imp`;

  // カスタムリンク発火
  tl(linkName, {});
};
