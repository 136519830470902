/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-return-assign */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  JobCountDetailResponse,
  JobCountResponse,
  JobTypeLResponse,
  QualificationResponse,
  StationResponse,
  IndustryLResponse,
  FooterLinkPatternResponse,
} from '@doda/api-client/aspida/@types';
import { PLP_COMMA_PAGE_LIST } from '@doda/common/src/constants/seo/plpCommaPageList';
import { DODA_SITE_URL } from '../constants/urls';
import { makeStaticUrl } from '../logics/staticUrl';
import {
  AreaListOfSearchModal,
  CityOfSearchModal,
  PrefOfSearchModal,
  RouteOfSearchModal,
} from '../models/MasterCacheModel';
import { AppQueryParams, Item, QUERY_PARAMS } from '../constants/queryParams';
import {
  EMPLOYMENT_OTHER_ITEM,
  EMPLOYMENT,
} from '../constants/screenStructure/searchConditionItems';
import {
  ES_SEARCH_ITEMS,
  HA_SEARCH_ITEMS,
  NE_SEARCH_ITEMS,
  OP_SEARCH_ITEMS,
} from '../constants/searchItems';
import {
  ASTERISK,
  BLANK,
  BOX_NAME_FORMAT,
  BOX_NAME_FORMAT_PR,
  BOX_NAME_FORMAT_CI,
  BOX_NAME_FORMAT_CITY,
  BOX_NAME_FORMAT_EKI,
  BOX_NAME_FORMAT_NARROW_DOWN,
  BOX_NAME_FORMAT_OTHER,
  BOX_NAME_FORMAT_KODAWARI,
  BOX_NAME_FORMAT_KODAWARI_OTHER,
  BOX_NAME_FORMAT_QC,
  BOX_NAME_FORMAT_QC_NARROW_DOWN,
  BOX_NAME_FORMAT_QC_NARROW_DOWN_PR,
  BOX_NAME_FORMAT_QC_NARROW_DOWN_CI,
  BOX_NAME_NO_QUERY,
  COMMA_URL_ENCODE,
  DODA_SEARCH_RESULT_LEFTPANEL_AR,
  EKI,
  GYOSYU,
  GYOSYU_L,
  GYOSYU_S,
  KODAWARI_SEARCH,
  NEAR_STATION_COUNT,
  NENSHU,
  NUMERIC_0,
  NUMERIC_10,
  STAFF_AVG_AGE,
  STR_L,
  STR_M,
  STR_S,
  SURASHU,
  SYOKUSYU,
  SYOKUSYU_L,
  SYOKUSYU_M,
  SYOKUSYU_S,
  SEARCH_TYPE,
  DODA_SEARCH_RESULT_LEFTPANEL_IND,
  DODA_SEARCH_RESULT_LEFTPANEL_OC,
  KOYOUKEITAI,
  KOTEIKYU,
  PATTERN_PAGE,
} from '../constants/seo';
import { existProperty, existPropertyNumbers } from './objectUtil';
import { ownerOp } from './opUtil';
import { nonNullable } from './typeGuard';
import { PickByType, strictObjectKeys } from './typeUtil';

import { getJobCount } from '../services/getJobCountAPI';
import { formatString } from './stringUtil';
import { getSearchFacetCount } from './seo/common/facetCountUtil';
import { BreadCrumb } from '../interfaces/breadCrumb';

export const onlyOneKeyExists = (
  map: Record<string, any>,
  key: string
): boolean => {
  if (map[key]) {
    const num = existPropertyNumbers(map);
    return num === 1;
  }
  return false;
};

export const onlyOneKeyExistsInKodawari = (map: AppQueryParams): boolean => {
  const { op, ha, ne, es, k } = map;
  const num = existPropertyNumbers({ op, ha, ne, es, k });
  return num === 1;
};

export const isOnlyValueInMap = (map: Record<string, any>): boolean => {
  if (map) {
    return Object.keys(map).every((k) => {
      const value = map[k];
      if (!value) return true;
      if (k === 'op') return valueExistsInOp(value);
      if (k === 'ha') return isNotEmptyAndCommaZero(value);
      return valueExists(map[k]);
    });
  }
  return false;
};

export const isTwoValueInMap = (map: Record<string, any>): boolean => {
  if (map) {
    return Object.keys(map).every((k) => {
      const value = map[k];
      if (!value) return true;
      const op = ownerOp(map.op);
      if (
        !(
          (op?.length == 2 && !map.ha) ||
          (op?.length === 1 && map.ha?.length === 2)
        )
      )
        return false;
      if (k === 'op' && op?.length === 2) return twoValueExistsInOp(value);
      if (k === 'op' && op?.length === 1) return valueExistsInOp(value);
      if (k === 'ha') return isNotEmptyAndCommaZero(value);
      return valueExists(map[k]);
    });
  }
  return true;
};

/**
 * 判定：1軸 × フリーワード（ind × k）（oc × k）（ar × k）（pr × k）（ci × k）（wa × k）
 * @param query
 * @returns
 */
export const isTwoParamPreK = (query: AppQueryParams): boolean => {
  const { k, ind, oc, ar, pr, ci, wa, rt, st, op, ha, ne, es } = query;
  const num = getNumberOfKeyWithSingleValue({ ind, oc, ar, pr, ci, wa });
  const otherParam = [rt, st, op, ha, ne, es];
  if (k && num === 1 && isEmptyArray(otherParam)) {
    return true;
  }
  return false;
};

/**
 * 判定：こだわり1軸 × フリーワード（op × k）
 * @param query
 * @returns
 */
export const isTwoParamOpK = (query: AppQueryParams) => {
  const { k, ind, oc, ar, pr, ci, wa, rt, st, op, ha, ne, es } = query;
  const otherParam = [ind, oc, ar, pr, ci, wa, rt, st, ha, ne, es];
  return (
    k &&
    op &&
    op.length === 1 &&
    getOpName(op).length > 0 &&
    !otherParam.some((x) => x?.length)
  );
};

/**
 * 判定：こだわり1軸 × フリーワード（ha × k）
 * @param query
 * @returns
 */
export const isTwoParamHaK = (query: AppQueryParams) => {
  const { k, ind, oc, ar, pr, ci, wa, rt, st, op, ha, ne, es } = query;
  const otherParam = [ind, oc, ar, pr, ci, wa, rt, st, op, ne, es];
  return k && ha && haSelectCheck(ha) && !otherParam.some((x) => x?.length);
};

/**
 * 判定：こだわり1軸 × フリーワード（ne × k）
 * @param query
 * @returns
 */
export const isTwoParamNeK = (query: AppQueryParams) => {
  const { k, ind, oc, ar, pr, ci, wa, rt, st, op, ha, ne, es } = query;
  const otherParam = [ind, oc, ar, pr, ci, wa, rt, st, op, ha, es];
  return (
    k &&
    ne &&
    ne.length === 1 &&
    getEmployeesItemName(ne[0]).length > 0 &&
    !otherParam.some((x) => x?.length)
  );
};

/**
 * 判定：こだわり1軸 × フリーワード（es × k）
 * @param query
 * @returns
 */
export const isTwoParamEsK = (query: AppQueryParams) => {
  const { k, ind, oc, ar, pr, ci, wa, rt, st, op, ha, ne, es } = query;
  const otherParam = [ind, oc, ar, pr, ci, wa, rt, st, op, ha, ne];
  return (
    k &&
    es &&
    es.length === 1 &&
    getEstablishmentItemName(es[0]).length > 0 &&
    !otherParam.some((x) => x?.length)
  );
};

/**
 * 判定：LMSは正しい位置にあり、後ろは空かチェック（oc=01L53等の値の持ち方をNGとする）
 * @param value
 * @returns
 */
export const isExistsLMS = (
  value: string[] | string | undefined | null
): boolean => {
  // 値が１つか（カンマ等で複数指定でない）
  if (valueExists(value)) {
    // LMSが適切な位置にあるか。LMSの後続文字列が空か。
    if (Array.isArray(value)) {
      if (value[0].indexOf('L') == 2) {
        const valueAryL = value[0].split('L');
        return valueAryL[1].length <= 0;
      }
      if (value.indexOf('M') == 4) {
        const valueAryM = value[0].split('M');
        return valueAryM[1].length <= 0;
      }
      if (value.indexOf('S') == 6) {
        const valueAryS = value[0].split('S');
        return valueAryS[1].length <= 0;
      }
    }
  }
  return false;
};

export const valueExists = (
  value: string[] | string | undefined | null,
  size: number = 1
): boolean => {
  if (value) {
    if (Array.isArray(value)) return value.length === size;
    if (typeof value === 'string') {
      const valueAry = value.split(',');
      return valueAry.length === size;
    }
  }
  return false;
};

export const valueExistsInOp = (
  value: string[] | undefined | null,
  size: number = 1
): boolean => {
  if (value) {
    return ownerOp(value)?.length === size;
  }
  return false;
};

export const twoValueExistsInOp = (
  value: string[] | undefined | null,
  size: number = 2
): boolean => {
  if (value) {
    return ownerOp(value)?.length === size;
  }
  return false;
};

type KindCondition = Partial<
  Record<'op' | 'ind' | 'oc' | 'ar' | 'k' | 'kw' | 'ni', any>
>;

export const extractScreenKindCondition = (
  condition: Record<string, any>
): KindCondition => {
  const extractCond = ['op', 'ind', 'oc', 'ar', 'k', 'kw', 'ni'];
  const map = {};
  if (existProperty(condition)) {
    Object.keys(condition).forEach((key) =>
      extractCond.includes(key) ? (map[key] = condition[key]) : false
    );
    return map;
  }
  return map;
};

/**
 * 入力チェック（年収 ha）　※SEO対策ページ用
 *
 * （配列２つで「40,0」の形式か。配列1つ目「0：未設定」NG。配列2つ目は0固定。マスタ値か。）
 *
 * @param ha 年収
 * @return boolean true：OK（SEO対策ページとして妥当な値の持ち方） false：NG
 */
export const haSelectCheck = (ha: Item | undefined) => {
  if (!ha) return false;
  return (
    ha?.length === 2 &&
    ha[0] !== '0' &&
    ha[1] === '0' &&
    gethaItemName(ha[0]).length > 0
  );
};

/**
 * 画面URLとマスタURLを照合し、優先度を求める。
 *
 * ③分解した画面URLとマスタの設定URLの照合し、候補が複数有る場合は適合度を付けるメソッド
 * ④③でつけた適合度から下記の計算をし、優先度を求める。
 * ■優先度の求め方
 * ・URL_ID_1　= {"0-0"}適合度×10N-0 + {"0SeoConst.NUMERIC_MAINASU1"}適合度×10NSeoConst.NUMERIC_MAINASU1 ・・・ +
 * {"0-6"}適合度×10N-N ・・・
 * 　= 1×107 + 1×106 ・・・ + 1×100 ・・・
 * 1111111
 * ・URL_ID_2　= {"1-0"}適合度×10N-0 + {"1SeoConst.NUMERIC_MAINASU1"}適合度×10NSeoConst.NUMERIC_MAINASU1 ・・・ +
 * {"1-6"}適合度×10N-N ・・・
 * 22211111
 * ⇒優先度の値が一番大きいURL_ID=2を採用することに決定する。
 *
 * @param strUrl strUrl ()
 * @param listWurl listWurl
 * @return intCnt
 */
export const adaptUrl = (strUrl: string, listWurl: string[]) => {
  const browAry: string[] = insertGamenUrl(strUrl, listWurl);

  const clm: string[][] = insertMstUrl(strUrl, listWurl);

  const intMaxCnt = countMax(strUrl, listWurl);

  let intRec: number | null = null; // レコード

  let initial = 0; // 定義、初期化
  let resultUrl = 0; // レコードの優先度結果を格納

  for (let i = 0; i < clm.length; i++) {
    for (let j = 0; j < intMaxCnt; j++) {
      // browAry[j]空文字の場合はＯＫ
      if (browAry[j] !== null && clm[i][j]) {
        // *の場合
        if (browAry[j] === ASTERISK || clm[i][j] === ASTERISK) {
          resultUrl += 1 * NUMERIC_10 ** (intMaxCnt - j);

          // 完全一致した場合
        } else if (browAry[j] === clm[i][j]) {
          resultUrl += 2 * NUMERIC_10 ** (intMaxCnt - j);

          // 不一致の場合
        } else if (browAry[j] !== clm[i][j]) {
          resultUrl = 0;

          break;
        }
      }
    }

    // 優先度が一番高いレコードを採用する。
    if (initial < resultUrl) {
      initial = resultUrl;
      intRec = i;
    }
  }

  return intRec;
};

const insertGamenUrl = (strUrl: string, listWurl: string[]) => {
  const intMaxCnt = countMax(strUrl, listWurl);

  const methodclm: string[] = [];

  const arrWurl = strUrl.split(SURASHU).filter((i) => i);

  // URLを分解する
  for (let i = 0; i < intMaxCnt; i++) {
    if (i < arrWurl.length) {
      methodclm.push(arrWurl[i]);
      // 最大値Ｎよりも数が少ない場合、変数に*を入れる。
    } else {
      methodclm[i] = ASTERISK;
    }
  }

  return methodclm;
};

/**
 * マスタに設定したUrlを「／」区切りで２次元配列に保存する。
 * 最大値Ｎよりも数が少ない場合、変数に*を入れる。
 *
 * @param strUrl strUrl
 * @param listWurl listWurl
 * @return methodclm
 */
const insertMstUrl = (strUrl: string, listWurl: string[]) => {
  const intMaxCnt = countMax(strUrl, listWurl);

  const methodclm: string[][] = [];

  let strWurl = '';
  let arrWurl: string[] = [];

  // URLを分解する
  for (let i = 0; i < listWurl.length; i++) {
    strWurl = listWurl[i];
    arrWurl = strWurl.split(SURASHU);
    methodclm[i] = [];
    for (let j = 0; j < intMaxCnt; j++) {
      if (j < arrWurl.length) {
        methodclm[i][j] = arrWurl[j];

        // 最大値Ｎよりも数が少ない場合、変数に*を入れる。
      } else {
        methodclm[i][j] = ASTERISK;
      }
    }
  }

  return methodclm;
};

/**
 * マスタに設定したUrlを"/"で区切った数を数えるメソッド
 *
 * @param strUrl strUrl
 * @param listWurl listWurl
 * @return intCnt
 */
const countMax = (strUrl: string, listWurl: string[]) => {
  let intMaxCnt = countNum(SURASHU, strUrl);

  let strWurl: string = '';
  let arrWurl: string[] | null = null;

  for (let i = 0; i < listWurl.length; i++) {
    strWurl = listWurl[i];
    arrWurl = strWurl.split(SURASHU);

    if (intMaxCnt < arrWurl.length) {
      intMaxCnt = arrWurl.length;
    }
  }

  return intMaxCnt;
};

/**
 * 画面Urlを"/"で区切った数を数えるメソッド
 *
 * @param strDispute strDispute
 * @param strValues strValues
 * @return intCnt
 */
const countNum = (strDispute: string, strValues: string) => {
  if (strValues.indexOf(strDispute) != -1) {
    const ary = strValues.split(strDispute).filter((i) => i);
    return ary.length;
  }
  return 0;
};

export const gethaItemName = (id: string) => {
  if (id) {
    return HA_SEARCH_ITEMS.find((item) => item.id === id)?.seoText ?? '';
  }
  return '';
};
export const getEmployeesItemName = (id: string) => {
  if (id) {
    return NE_SEARCH_ITEMS.find((item) => item.id === id)?.seoText ?? '';
  }
  return '';
};
export const getEstablishmentItemName = (id: string) => {
  if (id) {
    return ES_SEARCH_ITEMS.find((item) => item.id === id)?.seoText ?? '';
  }
  return '';
};

export const getOpName = (id: string[]) => {
  const isOneValue = valueExistsInOp(id);
  if (isOneValue) {
    if (id.length > 1) {
      const isEmploymentOtherItem = id.join() === EMPLOYMENT_OTHER_ITEM.id;
      if (isEmploymentOtherItem) return EMPLOYMENT_OTHER_ITEM.name;
      return '';
    }
    if (id.length === 1) {
      const item = Object.values(OP_SEARCH_ITEMS).find(
        (item) => item.id === id[0]
      );
      if (item) return 'seoText' in item ? item.seoText : item?.name;
    }
  }
  return '';
};

export const getOpNames = (id: string[]) => {
  const nameList: string[] = [];
  if (id) {
    let str = id.join();
    const opList: string[] = [];

    if (str.indexOf(EMPLOYMENT_OTHER_ITEM.id) > -1) {
      str = str.replace(EMPLOYMENT_OTHER_ITEM.id, '');
      opList.push(OP_SEARCH_ITEMS.other.id);
    }
    str.split(',').forEach((i) => (i ? opList.push(i) : false));

    const itemList = Object.values(OP_SEARCH_ITEMS);

    opList.forEach((i) => {
      let name = '';
      if (i === OP_SEARCH_ITEMS.other.id) {
        name = EMPLOYMENT_OTHER_ITEM.name;
      } else {
        name = itemList.find((item) => item.id === i)?.name ?? '';
      }
      if (name) {
        nameList.push(name);
      }
    });
  }
  return nameList;
};

export const shouldEditOcText = (query: AppQueryParams, kodawari: string) => {
  const { op, oc, ha, ne, es, k } = query;
  if (valueExists(oc, 2)) return false;
  const num = existPropertyNumbers({ op, ha, ne, es, k });
  if (num > 1 && !isTwoValueInMap(query)) return false;
  if (num === 1) {
    const isOnly =
      isOnlyValueInMap({ op, ha, ne, es, k }) || valueExistsInOp(op, 2);
    if (!isOnly) return false;
    if (!kodawari) return false;
    return true;
  }
  return true;
};
export const shouldEditArText = (query: AppQueryParams, kodawari: string) => {
  const { ar, pr, ci, wa, st, rt } = query;
  const num = getNumberOfKeyWithSingleValue({ ar, pr, ci, wa, st, rt });
  if (num === 1) {
    const shouldEditOc = shouldEditOcText(query, kodawari);
    if (!shouldEditOc) return false;
    return true;
  }
  return false;
};

export const shouldEditIndText = (query: AppQueryParams, kodawari: string) => {
  const { ind } = query;
  if (valueExists(ind)) {
    // エリア、都道府県が、存在しないor値が一つの時
    const { ar, pr, ci, wa } = query;
    if (
      valueExists(ar) ||
      valueExists(pr) ||
      valueExists(ci) ||
      valueExists(wa)
    ) {
      const shouldEditOc = shouldEditOcText(query, kodawari);
      if (!shouldEditOc) return false;
    }
    return true;
  }
  return false;
};

const getNumberOfKeyWithSingleValue = (
  map: Record<string, string[] | undefined>
) => {
  const singleValueList = Object.values(map).filter((i) =>
    i ? i.length === 1 : false
  );
  return singleValueList.length;
};

/**
 * こだわり条件が、空及び、1つのみの場合、Trueを返す
 * @param op
 */
export const checkEmptyOrOnlyOp = (op: Item | undefined): boolean => {
  op = ownerOp(op);
  if (op) {
    return op.length <= 1;
  }
  return true;
};

/**
 * 選択中のこだわり条件がマスタに存在しているかつ、いずれかのこだわりが1つだけ選択されている場合、Trueを返す
 * @param root0
 */
export const checkKodawariMaster = ({ op, ha, ne, es }: AppQueryParams) => {
  const kodawariQuery = { op, ha, ne, es };

  if (isMultiSelect(op, ha, ne, es)) return false;
  if (!isOnlyValueInMap(kodawariQuery)) return false;

  const searchItem = {
    op: Object.values(OP_SEARCH_ITEMS).map((value) => value.id),
    ha: HA_SEARCH_ITEMS.map((item) => item.id), // 下限のみ
    ne: NE_SEARCH_ITEMS.map((item) => item.id),
    es: ES_SEARCH_ITEMS.map((item) => item.id),
  };

  // 選択中のこだわり条件が何かを特定する
  const selectedKodawariName = strictObjectKeys(searchItem).find((key) => {
    const value = kodawariQuery[key];
    return key === 'ha'
      ? isNotEmptyAndCommaZero(value)
      : key === 'op'
        ? isNotEmptyAndNotComma(ownerOp(value))
        : isNotEmptyAndNotComma(value);
  });

  if (!selectedKodawariName) return false;

  const searchData = searchItem[selectedKodawariName];
  const selectedKodawariId = kodawariQuery[selectedKodawariName];

  if (selectedKodawariId === undefined) return false;

  // 選択中のこだわり条件が登録マスタに存在するものかどうかチェックする
  return searchData.some((item) => item === selectedKodawariId[0]);
};

/**
 * こだわり2軸用
 * 選択中のこだわり条件がマスタに存在しているかつ、op×opまたはop×haの場合、Trueを返す
 * @param root0
 */
export const checkKodawariMasterForOpMulti = ({ op, ha }: AppQueryParams) => {
  const kodawariQuery = { op, ha };

  if (!isTwoValueInMap(kodawariQuery)) return false;

  const searchItem = {
    op: Object.values(OP_SEARCH_ITEMS).map((value) => value.id),
    ha: HA_SEARCH_ITEMS.map((item) => item.id), // 下限のみ
  };

  // 選択中のこだわり条件が何かを特定する
  const selectedKodawariName = strictObjectKeys(searchItem).find((key) => {
    const value = kodawariQuery[key];
    if (kodawariQuery.op && key == 'op') {
      return true;
    }
    if (kodawariQuery.ha && key == 'ha') {
      return key === 'ha'
        ? isNotEmptyAndCommaZero(value)
        : key === 'op'
          ? isNotEmptyAndNotComma(ownerOp(value))
          : isNotEmptyAndNotComma(value);
    }
  });

  if (!selectedKodawariName) return false;

  const searchData = searchItem[selectedKodawariName];
  const selectedKodawariId = kodawariQuery[selectedKodawariName];

  if (selectedKodawariId === undefined) return false;

  // 選択中のこだわり条件が登録マスタに存在するものかどうかチェックする
  return searchData.some((item) => item === selectedKodawariId[0]);
};

/**
 * 引数1が空白ではないかつカンマを含めてない、他の引数すべては空白の場合　TRUEを戻す
 * @param p1
 * @param {...any} otherParams
 */
export const isOnlyParam = (
  p1: Item | undefined,
  ...otherParams: (Item | undefined)[]
): boolean => {
  return isNotEmptyAndNotComma(p1) && isEmptyArray(otherParams);
};

/**
 * 引数1,2が空白ではないかつカンマを含めてない、他の引数すべては空白の場合　TRUEを戻す
 * @param p1
 * @param p2
 * @param {...any} otherParams
 */
export const isTwoParam = (
  p1: Item | undefined,
  p2: Item | undefined,
  ...otherParams: (Item | undefined)[]
): boolean => {
  return (
    isNotEmptyAndNotComma(p1) &&
    isNotEmptyAndNotComma(p2) &&
    isEmptyArray(otherParams)
  );
};

/**
 * 引数1がその他（FCオーナー・業務委託など）の考慮後空白ではないかつカンマを含めてない, 引数2が値ありでかつカンマを含めてない（複数選択でない）、他の引数すべては空白の場合　TRUEを戻す
 * @param p1
 * @param p2
 * @param {...any} otherParams
 */
export const isTwoParamForOp = (
  p1: Item | undefined,
  p2: Item | undefined,
  ...otherParams: (Item | undefined)[]
): boolean => {
  return (
    isNotEmptyAndNotCommaForOp(p1) &&
    isNotEmptyAndNotComma(p2) &&
    isEmptyArray(otherParams)
  );
};

/**
 * 引数1がカンマゼロ",0"を含み, 他の引数すべては空白の場合　TRUEを戻す
 * 引数1には年収(ha)をあててください。
 * @param p1
 * @param {...any} otherParams
 */
export const isOnlyParamForHa = (
  p1: Item | undefined,
  ...otherParams: (Item | undefined)[]
): boolean => {
  return isNotEmptyAndCommaZero(p1) && isEmptyArray(otherParams);
};

/**
 * 引数1がカンマゼロ",0"を含み, 引数2が値ありでかつカンマを含めてない（複数選択でない）、他の引数すべては空白の場合　TRUEを戻す
 * @param p1
 * @param p2
 * @param {...any} otherParams
 */
export const isTwoParamForHa = (
  p1: Item | undefined,
  p2: Item | undefined,
  ...otherParams: (Item | undefined)[]
): boolean => {
  return (
    isNotEmptyAndCommaZero(p1) &&
    isNotEmptyAndNotComma(p2) &&
    isEmptyArray(otherParams)
  );
};

/**
 * 引き数の値で複数掛け合わせをしているかどうか判定する
 * @param {...any} arrayStr
 */
export const isMultiSelect = (...arrayStr: (Item | undefined)[]): boolean => {
  const isMultiple = arrayStr.filter(
    (item) => typeof item !== 'undefined'
  ).length;
  return isMultiple > 1;
};

/**
 * 文字列がundefinedでなく、複数選択でないか判定する
 * @param str
 */
export const isNotEmptyAndNotComma = (str: Item | undefined): boolean => {
  return typeof str !== 'undefined' && str.length === 1;
};

/**
 * 文字列がundefinedでなく、複数選択か判定する
 * @param str
 */
export const isNotEmptyAndComma = (str: Item | undefined): boolean => {
  return typeof str !== 'undefined' && str.length > 1;
};

/**
 * 各配列の中身が1つ以上か判定する
 * @param {...any} arrayStr
 */
export const isNotEmptyAndCommaArray = (
  ...arrayStr: (Item | undefined)[]
): boolean => arrayStr.some((str) => isNotEmptyAndComma(str));

/**
 * 空文字の配列か判定する
 * @param arrayStr
 */
export const isEmptyArray = (arrayStr: (Item | undefined)[]): boolean => {
  // 空配列または配列がすべてundefiendの場合はtrue
  return (
    arrayStr.length === 0 || arrayStr.every((str) => typeof str === 'undefined')
  );
};

/**
 * こだわり条件（op）がその他（FCオーナー・業務委託など）を考慮した後、undefinedでなく、複数選択でないか判定する
 * @param str
 */
export const isNotEmptyAndNotCommaForOp = (str: Item | undefined): boolean => {
  str = ownerOp(str);
  return typeof str !== 'undefined' && str.length === 1;
};

/**
 * 年収（ha）がundefinedでなく、～以上のみが設定されている状態
 * @param str
 */
export const isNotEmptyAndCommaZero = (str: Item | undefined): boolean => {
  return (
    typeof str !== 'undefined' &&
    str.length === 2 &&
    str[0] !== '0' &&
    str[1] === '0'
  );
};

/**
 * 年収（ha）がundefinedでなく、カンマゼロ「,0」を含まないか判定する
 * @param str
 */
export const isNotEmptyAndNotCommaZero = (str: Item | undefined): boolean => {
  return (
    typeof str !== 'undefined' &&
    str.length === 2 &&
    (str[0] === '0' || str[1] !== '0')
  );
};

/**
 * 正規化URLを判別する
 * @param {string} path
 * @returns true: 正規化URL, false: 通常URL
 */
export const isCanonical = (path) => {
  return /\/(j|h)_/.test(path);
};

/**
 * 都道府県IDで市区町村マスタを取得
 *
 * @param prefId 都道府県ID
 * @param cityList
 * @return 市区町村マスタ
 */
export const getCityByPrefectureId = (
  prefId: string,
  cityList: CityOfSearchModal[] | null
) => {
  const filterCityList = cityList?.filter((city) => city.prefId === prefId);
  const filterCityMap = filterCityList?.map((item) => [item.id, item.name]);

  return filterCityMap;
};

/**
 * 都道府県IDから駅マスタを取得
 * @param prefId 都道府県ID
 * @param stationList 駅マスタ
 * @returns 都道府県駅マスタ
 */
export const getStationsByPrefId = (
  prefId: string,
  stationList: StationResponse[]
) => {
  const filterStationList = stationList?.filter(
    (station) =>
      station.prefectureId === prefId && station.isDisplayedStationByPrefecture
  );

  const filterStationMap = filterStationList?.map((item) => [
    item.id,
    item.name,
  ]);

  return filterStationMap;
};

/**
 * 団体コード（市区町村コード）から駅マスタ取得
 * @param worgcd 団体コード（市区町村コード）
 * @param stationList 駅マスタ
 * @returns 市区町村駅マスタ
 */
export const getStationsByWorgcd = (
  worgcd: string,
  stationList: StationResponse[]
) => {
  const filterStationList = stationList.filter(
    (station) => station.cityId === worgcd
  );

  const filterStationMap = filterStationList.map((item) => [
    item.id,
    item.name,
  ]);

  return filterStationMap;
};

/**
 * 団体コード（市区町村コード）から政令指定都市マスタ取得
 * @param cityId 団体コード（市区町村コード）
 * @param cityList 市区町村マスタ
 * @returns 政令指定都市マスタ
 */
export const getWardListByCityId = (
  cityId: string,
  cityList: CityOfSearchModal[]
) => {
  const filterWardList = cityList.find((city) => city.id === cityId)?.wardList;
  const filterWardListMap = filterWardList?.map((item) => [item.id, item.name]);

  return filterWardListMap;
};

/**
 * PrIdを取得する
 * @param boxName
 * @param key
 * @returns PrId
 */
export const getPrId = (boxName: string, key: string) => {
  if (
    GYOSYU === boxName ||
    SYOKUSYU === boxName ||
    GYOSYU_L === boxName ||
    SYOKUSYU_L === boxName
  ) {
    return key + STR_L;
  }
  if (SYOKUSYU_M === boxName) {
    return key + STR_M;
  }
  if (GYOSYU_S === boxName || SYOKUSYU_S === boxName) {
    return key + STR_S;
  }
  if (NENSHU === boxName) {
    return key + COMMA_URL_ENCODE + String(NUMERIC_0);
  }
  return key;
};

/**
 * 市区町村IDから都道府県IDを作成
 * @param cityId
 * @param cities
 */
export const createPrefectureIdByCityId = (
  cityId: string,
  cities: CityOfSearchModal[]
) => {
  const prefId = cities.find((city) => city.id === cityId)?.prefId;
  return prefId;
};

/**
 * 市区町村IDから都道府県名を取得
 * @param cityId 市区町村ID
 * @param prefectureList
 * @param cities
 * @returns 都道府県名
 */
export const getPrefectureNameByCityId = (
  cityId: string | undefined,
  prefectureList: PrefOfSearchModal[],
  cities: CityOfSearchModal[]
) => {
  if (typeof cityId === 'undefined') return;
  const prefId = createPrefectureIdByCityId(cityId, cities);
  return prefectureList.find((pref) => pref.id === prefId)?.name;
};

/**
 * 市区町村IDから市区町村名を取得
 * @param cityId 市区町村ID
 * @param cityList
 * @returns 市区町村名
 */
export const getCityNameByCityId = (
  cityId: string,
  cityList: CityOfSearchModal[]
) => {
  return cityList.find((city) => city.id === cityId)?.name;
};

/**
 * 市区団体コード（wa）から政令指定都市名を取得
 * @param wardId 市区団体コード（wa）
 * @param cityList
 * @returns 政令指定都市名
 */
export const getWardNameByWardId = (
  wardId: string,
  cityList: CityOfSearchModal[]
) => {
  const wardList = cityList.flatMap((city) => city.wardList);
  return wardList.find((ward) => ward.id === wardId)?.name;
};

/**
 * 団体コードが政令指定都市か判定する
 * @param worgcd 団体コード
 * @param cityList
 * @returns boolean（政令指定都市だったらtrue）
 */
export const getIsWdndcty = (worgcd: string, cityList: CityOfSearchModal[]) => {
  return (
    cityList.find((city) => city.id === worgcd)?.isOrdinanceDesignatedCity ||
    false
  );
};

/**
 * 政令指定都市(区)IDから団体コードを取得
 * @param wardId 政令指定都市ID
 * @param cityList
 * @returns 団体コード
 */
export const getWorgcdByWardId = (
  wardId: string,
  cityList: CityOfSearchModal[]
) => {
  return cityList.find((city) =>
    city.wardList.find((ward) => ward.id === wardId)
  )?.id;
};

/**
 * 団体コードから市区町村を取得
 * @param worgcd 団体コード
 * @param cityList
 * @returns 市区町村データ
 */
export const getCityByWorgcd = (
  worgcd: string,
  cityList: CityOfSearchModal[]
) => {
  return cityList.find((city) => city.id === worgcd);
};

/**
 * 駅IDから路線コードが最小の路線の前後5駅の情報を取得する
 * @param st 駅ID
 * @param routeList
 * @returns 路線コードが最小の前後5駅のMap
 */
export const getNearStationsMapBySt = (
  st: string,
  routeList: RouteOfSearchModal[]
) => {
  // 路線コードが最小の駅路線情報を取得する
  const firstRouteBySt = routeList.find((route) =>
    route.stationList.some((station) => station.id === st)
  );

  // 駅のindexを取得
  const stIndex = firstRouteBySt?.stationList.findIndex(
    (station) => station.id === st
  );

  if (typeof stIndex === 'undefined' || stIndex === -1) return undefined;

  // 路線コードが最小の路線の前後5駅の情報を取得する
  const nearStations = firstRouteBySt?.stationList.slice(
    stIndex - NEAR_STATION_COUNT <= 0 ? 0 : stIndex - NEAR_STATION_COUNT,
    stIndex + NEAR_STATION_COUNT + 1
  );
  // 検索した駅は除く
  const excludeThisStNearStations = nearStations?.filter(
    (station) => station.id !== st
  );
  // mapに変換する
  const nearStationsMap = excludeThisStNearStations?.map((item) => [
    item.id,
    item.name,
  ]);

  return nearStationsMap;
};

/**
 * 業種小分類IDから、属する業種大分類IDを取得する
 * @param ind
 * @param industryLList
 */
export const getIndLIdByIndSId = (
  ind: string,
  industryLList: IndustryLResponse[]
) => {
  // 引き数indの末尾('S')を削除
  const industrySId = ind.slice(0, -1);

  return industryLList.find((ind) =>
    ind.industrySList.find((indS) => indS.id === industrySId)
  )?.id;
};

/**
 * 業種大分類IDから、属する業種小分類マスタを取得する
 * @param indLId
 * @param industryLList
 */
export const getIndSListByIndLId = (
  indLId: string,
  industryLList: IndustryLResponse[]
) => {
  const industrySList = industryLList.find(
    (ind) => ind.id === indLId
  )?.industrySList;
  const filterIndSListMap = industrySList?.map((item) => [item.id, item.name]);

  return filterIndSListMap;
};

/** SEOタイトルテンプレート種別 */
export type TitleFormatType =
  /** 駅 */
  | 'ST'
  /** 市区町村 */
  | 'CI'
  /** 政令指定都市 */
  | 'WA'
  /** その他 */
  | 'OTHER'
  /** 絞り込み */
  | 'NARROW_DOWN'
  /** 同階層 */
  | 'SAME_STAGE'
  /** 同階層（都道府県） */
  | 'SAME_STAGE_PR'
  /** 同階層（市区町村） */
  | 'SAME_STAGE_CI'
  /** こだわり */
  | 'KODAWARI'
  /** こだわり2軸の時、1軸のみ残して絞り込み */
  | 'KODAWARI_OTHER'
  /** 条件指定なし */
  | 'NO_QUERY'
  /** 資格（同階層） */
  | 'QC_SAME_STAGE'
  /** 資格（絞り込み） */
  | 'QC_NARROW_DOWN'
  /** 資格（絞り込み_都道府県） */
  | 'QC_NARROW_DOWN_PR'
  /** 資格（絞り込み_市区町村） */
  | 'QC_NARROW_DOWN_AREA';

export type FacetName = Exclude<
  keyof PickByType<JobCountResponse, JobCountDetailResponse[] | undefined>,
  undefined
>;

/**
 * DODA用リンクパラメータが三つがある時のボックス作成部品
 * @param boxType リンクボックスタイプ
 * @param data mapデータ
 * @param kiId 選択した項目ID
 * @param kiNm 選択した項目名
 * @param pt1 パラメータ名1
 * @param pt2 パラメータ名2
 * @param pt3 パラメータ名3
 * @param vl3 パラメータ名3の設定値
 * @param searchFacetCount
 * @param titleType タイトル種別判別
 * @return リンクボックス
 */
export const getLinkBoxForThree = (
  boxType: string,
  data: string[][],
  kiId: string,
  kiNm: string,
  pt1: string,
  pt2: string,
  pt3: string,
  vl3: string,
  searchFacetCount: SearchFacetCount[string],
  titleType: TitleFormatType
) => {
  const boxName = getBoxName(titleType, kiNm, boxType);

  const boxLinks = data
    .map(([key, value]) => {
      const prId = getPrId(boxType, key);

      // 件数取得
      const facetCount = searchFacetCount?.find(
        (facet) => facet.id === key
      )?.count;

      const linkURL = makeStaticUrl(
        { [pt1]: prId, [pt2]: kiId, [pt3]: vl3 },
        DODA_SITE_URL.jobSearchList
      );

      return {
        linkText: value,
        linkURL: facetCount ? linkURL : null,
      };
    })
    .filter(nonNullable);

  return {
    boxName,
    boxLinks,
  };
};

/**
 * BoxNameを取得する
 * @param titleType
 * @param p1
 * @param p2
 * @returns string
 */
const getBoxName = (titleType: TitleFormatType, p1: string, p2: string) => {
  switch (titleType) {
    case 'ST':
      return formatString(BOX_NAME_FORMAT_EKI, [p1, p2]);
    case 'CI': // CIとWAは同じテンプレートを利用する
    case 'WA':
      return formatString(BOX_NAME_FORMAT_CITY, [p1]);
    case 'OTHER':
      return formatString(BOX_NAME_FORMAT, [p1, p2]);
    case 'NARROW_DOWN':
      return formatString(BOX_NAME_FORMAT_NARROW_DOWN, [p1, p2]);
    case 'SAME_STAGE':
      return formatString(BOX_NAME_FORMAT_OTHER, [p1]);
    case 'SAME_STAGE_PR':
      return formatString(BOX_NAME_FORMAT_PR, [p1]);
    case 'SAME_STAGE_CI':
      return formatString(BOX_NAME_FORMAT_CI, [p1]);
    case 'KODAWARI':
      return BOX_NAME_FORMAT_KODAWARI;
    case 'KODAWARI_OTHER':
      return formatString(BOX_NAME_FORMAT_KODAWARI_OTHER, [p1]);
    case 'NO_QUERY':
      return BOX_NAME_NO_QUERY;
    case 'QC_SAME_STAGE':
      return formatString(BOX_NAME_FORMAT_QC, [p1]);
    case 'QC_NARROW_DOWN':
      return formatString(BOX_NAME_FORMAT_QC_NARROW_DOWN, [p1]);
    case 'QC_NARROW_DOWN_PR':
      return formatString(BOX_NAME_FORMAT_QC_NARROW_DOWN_PR, [p1]);
    case 'QC_NARROW_DOWN_AREA':
      return formatString(BOX_NAME_FORMAT_QC_NARROW_DOWN_CI, [p1]);
    default:
      return formatString(BOX_NAME_FORMAT, [p1, p2]);
  }
};

/**
 * 駅用リンクボックス作成
 * @param data mapデータ
 * @param kiNm 駅名あるいは都道府県名
 * @param searchFacetCount ファセット
 * @return リンクボックス
 */
export const getStLinkBoxRtSt = async (
  data: string[][],
  kiNm: string,
  searchFacetCount: SearchFacetCount[string]
) => {
  const linkBox = getLinkBoxForThree(
    EKI,
    data,
    BLANK,
    kiNm,
    QUERY_PARAMS.st,
    BLANK,
    QUERY_PARAMS.preBtn,
    String(DODA_SEARCH_RESULT_LEFTPANEL_AR),
    searchFacetCount,
    'ST'
  );

  return linkBox;
};

/**
 * DODA用リンクパラメータが四つがある時のボックス作成部品
 * @param query
 * @param boxType リンクボックスタイプ
 * @param data マスタデータ
 * @param kiId 選択した項目ID
 * @param kiNm 選択した項目名
 * @param pt1 パラメータ名1
 * @param pt2 パラメータ名2
 * @param vl2 パラメータ名2の設定値
 * @param pt3 パラメータ名3
 * @param vl3 パラメータ名3の設定値
 * @param pt4 パラメータ名4
 * @param vl4 パラメータ名4の設定値
 * @param searchFacetCount
 * @param titleType タイトル種別判別
 * @param categoryName 選択中の業種分類 or 職種分類名 ( ind / oc 以外はBLANKで問題無 )
 * @return リンクボックス
 */
export const getLinkBoxForFour = (
  query: AppQueryParams,
  boxType: string,
  data: string[][],
  kiId: string | undefined,
  kiNm: string,
  pt1: string,
  pt2: string,
  vl2: string,
  pt3: string,
  vl3: string,
  pt4: string,
  vl4: string,
  searchFacetCount: SearchFacetCount[string],
  titleType: TitleFormatType,
  categoryName: string
) => {
  // 資格 × hoge のboxLinksを返すパターンのみtrueにする
  const targetTitleTypeList = [
    'NARROW_DOWN',
    'QC_SAME_STAGE',
    'QC_NARROW_DOWN_PR',
    'QC_NARROW_DOWN_AREA',
  ];
  const isCareerRecommendJobListHref =
    !!query.qc && targetTitleTypeList.includes(titleType);

  const boxName = getBoxName(titleType, kiNm, boxType);
  const op = ownerOp(query.op);

  // op1軸のとき、「opの転職・求人情報を社員の平均年齢で絞り込む」,「opの転職・求人情報を社員の雇用形態で絞り込む」,「opの転職・求人情報を社員の固定給で絞り込む」
  // op×opのとき、「op×opの転職・求人情報を社員の平均年齢で絞り込む」,「op×opの転職・求人情報を社員の雇用形態で絞り込む」,「op×opの転職・求人情報を社員の固定給で絞り込む」
  // op×haのとき、「op×haの転職・求人情報を社員の平均年齢で絞り込む」,「op×haの転職・求人情報を社員の雇用形態で絞り込む」,「op×haの転職・求人情報を社員の固定給で絞り込む」
  // op1軸のとき、「opの転職・求人情報をこだわり条件で絞り込む」
  // op×opのとき、「op×opの転職・求人情報をこだわり条件で絞り込む」
  // op×haのとき、「op×haの転職・求人情報をこだわり条件で絞り込む」
  // op×opのとき、「opの転職・求人情報をほかのこだわり条件で探す」
  // op×haのとき、「opの転職・求人情報をほかのこだわり条件で探す」
  // のとき、sortをtrue（選択した項目IDと組み合わせるIDを'%2C'で連結）にする
  const sort =
    (titleType === 'NARROW_DOWN' &&
      (((STAFF_AVG_AGE === boxType ||
        KOYOUKEITAI === boxType ||
        KOTEIKYU === boxType) &&
        !!op?.length &&
        kiId &&
        (!pt2 || pt2 === 'ha')) ||
        (KODAWARI_SEARCH === boxType &&
          !!op?.length &&
          (!pt2 || pt2 === 'ha')))) ||
    (titleType === 'KODAWARI_OTHER' &&
      KODAWARI_SEARCH === boxType &&
      !!op?.length &&
      !pt2);

  const boxLinks = data
    .map(([key, value]) => {
      const prId = sort
        ? [kiId?.split(COMMA_URL_ENCODE), key]
            .filter(Boolean)
            .flat()
            .map(Number)
            .sort((a, b) => a - b)
            .join(COMMA_URL_ENCODE)
        : getPrId(categoryName, key);

      // 同階層リンクの場合、選択中の項目は除外する
      // ※資格経歴スキル一覧画面のこだわり同階層リンクの場合は、選択中のopもSEOフッターリンクに出力する
      if (prId === kiId) return null;

      // 件数取得
      const facetCount = searchFacetCount?.find(
        (facet) => facet.id === key
      )?.count;

      const linkURL = makeStaticUrl(
        { [pt1]: prId, [pt2]: vl2, [pt3]: vl3, [pt4]: vl4 },
        isCareerRecommendJobListHref
          ? DODA_SITE_URL.careerRecommendJobList
          : DODA_SITE_URL.jobSearchList
      );

      return {
        linkText: value,
        linkURL: facetCount ? linkURL : null,
      };
    })
    .filter(nonNullable);

  return {
    boxName,
    boxLinks,
  };
};

type SearchFacetCount = Record<string, JobCountDetailResponse[] | undefined>;

/** 取得するパラメータの個数 */
export type searchParamType =
  /** 優先度1位のみ */
  | 'FIRST'
  /** 優先度2位のみ */
  | 'SECOND'
  /** 優先度1位と2位 */
  | 'BOTH';

/**
 * dtoに設定されている強い項目とこだわり条件のうち
 * 取得対象外の種別を除いてパラメータ名と値を1つまたは2つ取得
 *
 * @param query
 * @param searchType 取得対象外の種別
 * @param paramType
 * @param takeQcParam
 * @return dtoに設定されている検索条件のパラメータ名
 */
export const searchSecondParam = (
  query: AppQueryParams,
  searchType: keyof typeof SEARCH_TYPE | undefined,
  paramType: searchParamType,
  takeQcParam = true
) => {
  const paramObject: {
    paramKeyList: string[];
    paramValueList: any[];
  } = {
    paramKeyList: [],
    paramValueList: [],
  };

  if (query.qc && searchType !== SEARCH_TYPE.QC && takeQcParam) {
    // query.qcが存在していても"takeQcParam = false" が渡された時にはqcをparamObjectに追加しない
    // （例：資格経歴スキル一覧画面の「勤務地の同階層リンク」生成時）
    paramObject.paramKeyList.push(QUERY_PARAMS.qc);
    paramObject.paramValueList.push(query.qc);
  }
  if (query.ind && searchType !== SEARCH_TYPE.IND) {
    paramObject.paramKeyList.push(QUERY_PARAMS.ind);
    paramObject.paramValueList.push(query.ind[0]);
  }
  if (query.ar && searchType !== SEARCH_TYPE.AR) {
    paramObject.paramKeyList.push(QUERY_PARAMS.ar);
    paramObject.paramValueList.push(query.ar[0]);
  }
  if (query.pr && searchType !== SEARCH_TYPE.PR) {
    paramObject.paramKeyList.push(QUERY_PARAMS.pr);
    paramObject.paramValueList.push(query.pr[0]);
  }
  if (query.ci && searchType !== SEARCH_TYPE.CI) {
    paramObject.paramKeyList.push(QUERY_PARAMS.ci);
    paramObject.paramValueList.push(query.ci[0]);
  }
  if (query.wa && searchType !== SEARCH_TYPE.WA) {
    paramObject.paramKeyList.push(QUERY_PARAMS.wa);
    paramObject.paramValueList.push(query.wa[0]);
  }
  if (query.oc && searchType !== SEARCH_TYPE.OC) {
    paramObject.paramKeyList.push(QUERY_PARAMS.oc);
    paramObject.paramValueList.push(query.oc[0]);
  }
  if (query.op && searchType !== SEARCH_TYPE.OP) {
    paramObject.paramKeyList.push(QUERY_PARAMS.op);
    const op = ownerOp(query.op);
    switch (paramType) {
      case 'FIRST': {
        paramObject.paramValueList.push(
          op?.map(Number).sort((a, b) => a - b)[0]
        );
        break;
      }
      case 'SECOND': {
        paramObject.paramValueList.push(
          op?.map(Number).sort((a, b) => a - b)[1]
        );
        break;
      }
      case 'BOTH': {
        paramObject.paramValueList.push(
          op?.map(Number).sort((a, b) => a - b)[0]
        );
        paramObject.paramValueList.push(
          op?.map(Number).sort((a, b) => a - b)[1]
        );
        break;
      }
      default: {
        break;
      }
    }
  }
  if (query.ha && searchType !== SEARCH_TYPE.HA) {
    if (!query.op || paramType === 'SECOND' || paramType === 'BOTH') {
      paramObject.paramKeyList.push(QUERY_PARAMS.ha);
      paramObject.paramValueList.push(query.ha.join(COMMA_URL_ENCODE));
    }
  }
  if (query.ne) {
    paramObject.paramKeyList.push(QUERY_PARAMS.ne);
    paramObject.paramValueList.push(query.ne[0]);
  }
  if (query.es) {
    paramObject.paramKeyList.push(QUERY_PARAMS.es);
    paramObject.paramValueList.push(query.es[0]);
  }

  if (
    paramObject.paramKeyList.length < 3 &&
    paramObject.paramValueList.length < 3
  ) {
    paramObject.paramKeyList.push(BLANK);
    paramObject.paramValueList.push(BLANK);
  }

  return paramObject;
};

/**
 * 「XXをこだわり条件で絞り込む」「XXをほかのこだわり条件で探す」用
 * dtoに設定されている強い項目とこだわり条件のうち
 * 取得対象外の種別を除いてパラメータ名と値を1つだけ取得
 *
 * @param query
 * @param searchType 取得対象外の種別
 * @param searchType2 取得対象外の種別2
 * @param paramType
 * @return dtoに設定されている検索条件のパラメータ名
 */
export const searchSecondParamBySearchTypes = (
  query: AppQueryParams,
  searchType: keyof typeof SEARCH_TYPE,
  searchType2: keyof typeof SEARCH_TYPE | undefined,
  paramType: searchParamType
) => {
  const paramObject: {
    paramKeyList: string[];
    paramValueList: any[];
  } = {
    paramKeyList: [],
    paramValueList: [],
  };

  if (query.qc) {
    if (searchType !== SEARCH_TYPE.QC && searchType2 !== SEARCH_TYPE.QC) {
      paramObject.paramKeyList.push(QUERY_PARAMS.qc);
      paramObject.paramValueList.push(query.qc);
    }
  }

  if (query.ind) {
    if (searchType !== SEARCH_TYPE.IND && searchType2 !== SEARCH_TYPE.IND) {
      paramObject.paramKeyList.push(QUERY_PARAMS.ind);
      paramObject.paramValueList.push(query.ind[0]);
    }
  }

  if (query.ar) {
    if (searchType !== SEARCH_TYPE.AR && searchType2 !== SEARCH_TYPE.AR) {
      paramObject.paramKeyList.push(QUERY_PARAMS.ar);
      paramObject.paramValueList.push(query.ar[0]);
    }
  }

  if (query.pr) {
    if (searchType !== SEARCH_TYPE.PR && searchType2 !== SEARCH_TYPE.PR) {
      paramObject.paramKeyList.push(QUERY_PARAMS.pr);
      paramObject.paramValueList.push(query.pr[0]);
    }
  }

  if (query.ci) {
    if (searchType !== SEARCH_TYPE.CI && searchType2 !== SEARCH_TYPE.CI) {
      paramObject.paramKeyList.push(QUERY_PARAMS.ci);
      paramObject.paramValueList.push(query.ci[0]);
    }
  }

  if (query.wa) {
    if (searchType !== SEARCH_TYPE.WA && searchType2 !== SEARCH_TYPE.WA) {
      paramObject.paramKeyList.push(QUERY_PARAMS.wa);
      paramObject.paramValueList.push(query.wa[0]);
    }
  }

  if (query.oc) {
    if (searchType !== SEARCH_TYPE.OC && searchType2 !== SEARCH_TYPE.OC) {
      paramObject.paramKeyList.push(QUERY_PARAMS.oc);
      paramObject.paramValueList.push(query.oc[0]);
    }
  }

  if (query.op) {
    if (searchType !== SEARCH_TYPE.OP && searchType2 !== SEARCH_TYPE.OP) {
      paramObject.paramKeyList.push(QUERY_PARAMS.op);
      const op = ownerOp(query.op);
      switch (paramType) {
        case 'FIRST': {
          paramObject.paramValueList.push(
            op?.map(Number).sort((a, b) => a - b)[0]
          );
          break;
        }
        case 'SECOND': {
          paramObject.paramValueList.push(
            op?.map(Number).sort((a, b) => a - b)[1]
          );
          break;
        }
        case 'BOTH': {
          paramObject.paramValueList.push(
            op
              ?.map(Number)
              .sort((a, b) => a - b)
              .join(COMMA_URL_ENCODE)
          );
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  if (query.ha) {
    if (searchType !== SEARCH_TYPE.HA && searchType2 !== SEARCH_TYPE.HA) {
      if (!(query.op && paramType === 'FIRST')) {
        paramObject.paramKeyList.push(QUERY_PARAMS.ha);
        paramObject.paramValueList.push(query.ha.join(COMMA_URL_ENCODE));
      }
    }
  }

  if (query.ne) {
    if (searchType2 !== SEARCH_TYPE.NE) {
      paramObject.paramKeyList.push(QUERY_PARAMS.ne);
      paramObject.paramValueList.push(query.ne[0]);
    }
  }

  if (query.es) {
    if (searchType2 !== SEARCH_TYPE.ES) {
      paramObject.paramKeyList.push(QUERY_PARAMS.es);
      paramObject.paramValueList.push(query.es[0]);
    }
  }

  if (
    paramObject.paramKeyList.length < 3 &&
    paramObject.paramValueList.length < 3
  ) {
    paramObject.paramKeyList.push(BLANK);
    paramObject.paramValueList.push(BLANK);
  }

  return paramObject;
};

/**
 * op×opまたはop×haの場合用
 * seoフッターリンクのboxNameとして2番目に優先度の高い選択中の項目、または優先度が1番目と2番目を連結した項目を設定する
 * 例：$選択中の項目$の転職・求人情報を$対象$で絞り込む
 * @param query
 * @param isConcat
 * @return 選択中の項目名
 */
export const getSelectedConditionsNameForOpMulti = (
  query: AppQueryParams,
  isConcat: boolean
) => {
  const { ha } = query;
  const op = ownerOp(query.op);
  if (!op) return '';

  const sortedOp = op.map(Number).sort((a, b) => a - b);
  const findNameInOpItems = (index: number) =>
    Object.values(OP_SEARCH_ITEMS).find(
      (item) => item.id === sortedOp[index]?.toString()
    )?.name;

  if (op.length == 2) {
    const selectedKodawariInfo = isConcat
      ? `${findNameInOpItems(0)}、${findNameInOpItems(1)}`
      : findNameInOpItems(1) || '';
    return selectedKodawariInfo;
  }

  if (op.length === 1 && ha && ha.length === 2) {
    const haIds = HA_SEARCH_ITEMS.map((item) => item.id);
    const selectedNnenshuInfo = HA_SEARCH_ITEMS.filter((item) =>
      haIds.includes(item.id)
    )
      .map((item) => [item.id, item.seoText])
      .find((item) => item[0] === ha[0]);
    if (isConcat) {
      const selectedKodawariInfo = findNameInOpItems(0);
      return `${selectedKodawariInfo}、${selectedNnenshuInfo?.[1]}`;
    }
    return selectedNnenshuInfo ? selectedNnenshuInfo[1] : '';
  }

  return '';
};

/**
 * 業種の絞り込みリンク作成
 * @param query
 * @param selectedConditionsName
 * @param dispTopFlg
 * @param searchFacetCount
 * @param industries
 * @param paramType
 */
export const createNarrowDownIndSeoLink = async (
  query: AppQueryParams,
  selectedConditionsName: string,
  dispTopFlg: boolean,
  searchFacetCount: JobCountResponse,
  industries: IndustryLResponse[],
  paramType: searchParamType
) => {
  const ind = query.ind ? query.ind[0] : undefined;

  let facetKey: FacetName;
  const params = searchSecondParam(query, SEARCH_TYPE.IND, paramType);
  let targetIndustryMaster: string[][] | undefined;
  let categoryName: string = '';

  if (dispTopFlg) {
    facetKey = 'industryLList';
    targetIndustryMaster = industries.map((item) => [item.id, item.name]); // 業種大分類マスタ
    categoryName = GYOSYU_L;
  } else {
    if (!ind) return;

    // 業種大分類が選択されている時のみ、絞り込みリンクを表示させる
    if (ind.substring(ind.length - 1) === 'L') {
      facetKey = 'industrySList';
      const indLId = ind.slice(0, -1);
      targetIndustryMaster = getIndSListByIndLId(indLId, industries); // 同じ業種大分類に属する業種小分類
      categoryName = GYOSYU_S;
    } else return;
  }

  const preBtn = selectDrillDownType(query, SEARCH_TYPE.IND, dispTopFlg);

  if (!targetIndustryMaster) return undefined;

  // op2軸から1軸×op2軸の掛け合わせリンクを作成する場合
  const opMultiParamValue =
    paramType == 'BOTH' && !query.ha
      ? params.paramValueList
          .filter(Boolean)
          .flat()
          .map(Number)
          .sort((a, b) => a - b)
          .join(COMMA_URL_ENCODE)
      : undefined;

  // op×haから1軸×op×haの掛け合わせリンクを作成する場合
  const isOpAndHa = paramType == 'BOTH' && query.op && query.ha;

  const linkBox = getLinkBoxForFour(
    query,
    GYOSYU,
    targetIndustryMaster,
    BLANK,
    selectedConditionsName,
    QUERY_PARAMS.ind,
    params.paramKeyList[0],
    opMultiParamValue || params.paramValueList[0],
    opMultiParamValue ? BLANK : params.paramKeyList[1],
    opMultiParamValue
      ? BLANK
      : isOpAndHa
        ? params.paramValueList[2]
        : params.paramValueList[1],
    QUERY_PARAMS.preBtn,
    String(preBtn),
    searchFacetCount[facetKey],
    'NARROW_DOWN',
    categoryName
  );

  return linkBox;
};

/**
 * 職種の絞り込みリンク作成
 * @param query
 * @param selectedConditionsName
 * @param dispTopFlg
 * @param searchFacetCount
 * @param occupations
 * @param paramType
 */
export const createNarrowDownOcSeoLink = async (
  query: AppQueryParams,
  selectedConditionsName: string,
  dispTopFlg: boolean,
  searchFacetCount: JobCountResponse,
  occupations: JobTypeLResponse[],
  paramType: searchParamType
) => {
  const oc = query.oc ? query.oc[0] : undefined;

  let facetKey: FacetName;
  let categoryName: string;
  let targetOcMaster: string[][] | undefined;

  if (dispTopFlg) {
    facetKey = 'searchJobTypeLList';
    categoryName = SYOKUSYU;
    targetOcMaster = occupations.map((occupation) => [
      occupation.searchJobTypeId,
      occupation.searchJobTypeName,
    ]);
  } else {
    const ocSize = oc?.slice(-1);
    const ocId = oc?.replace(/L|M|S/, '');

    if (ocSize === 'L') {
      facetKey = 'searchJobTypeMList';
      categoryName = SYOKUSYU_M;
      targetOcMaster = occupations
        .find((occupation) => occupation.searchJobTypeId === ocId)
        ?.jobTypeMList?.map((item) => [
          item.searchJobTypeId,
          item.searchJobTypeName,
        ]);
    } else if (ocSize === 'M') {
      facetKey = 'searchJobTypeSList';
      categoryName = SYOKUSYU_S;
      targetOcMaster = occupations
        .flatMap((item) => item.jobTypeMList)
        .find((item) => item.searchJobTypeId === ocId)
        ?.jobTypeSList.map((item) => [
          item.searchJobTypeId,
          item.searchJobTypeName,
        ]);
    } else if (ocSize === 'S') {
      return undefined;
    } else {
      return undefined;
    }
  }

  const preBtn = selectDrillDownType(query, SEARCH_TYPE.OC, dispTopFlg);

  if (!targetOcMaster) return undefined;

  // op2軸から1軸×op2軸の掛け合わせリンクを作成する場合
  const opMultiParamValue =
    paramType == 'BOTH' && !query.ha
      ? searchSecondParam(query, SEARCH_TYPE.OC, paramType)
          .paramValueList.filter(Boolean)
          .flat()
          .map(Number)
          .sort((a, b) => a - b)
          .join(COMMA_URL_ENCODE)
      : undefined;

  // op×haから1軸×op×haの掛け合わせリンクを作成する場合
  const isOpAndHa = paramType == 'BOTH' && query.op && query.ha;

  const linkBox = getLinkBoxForFour(
    query,
    SYOKUSYU,
    targetOcMaster,
    BLANK,
    selectedConditionsName,
    QUERY_PARAMS.oc,
    searchSecondParam(query, SEARCH_TYPE.OC, paramType).paramKeyList[0],
    opMultiParamValue ||
      searchSecondParam(query, SEARCH_TYPE.OC, paramType).paramValueList[0],
    opMultiParamValue
      ? BLANK
      : searchSecondParam(query, SEARCH_TYPE.OC, paramType).paramKeyList[1],
    opMultiParamValue
      ? BLANK
      : isOpAndHa
        ? searchSecondParam(query, SEARCH_TYPE.OC, paramType).paramValueList[2]
        : searchSecondParam(query, SEARCH_TYPE.OC, paramType).paramValueList[1],
    QUERY_PARAMS.preBtn,
    String(preBtn),
    searchFacetCount[facetKey],
    'NARROW_DOWN',
    categoryName
  );

  return linkBox;
};

/**
 * op（1軸）「こだわり条件で絞り込む」追加データ生成
 * マスタの並びが後ろで10リンクに満たない場合は、マスタ冒頭の1に戻り、10リンク設置
 * @param op
 */
export const createAdditionalOpData = (op: string[] | undefined) => {
  // opが右記のものは除外（注力8枠、社員の平均年齢XX代、「57,58,59,64」）
  const removeIdList = [
    '3',
    '4',
    '8',
    '17',
    '21',
    '49',
    '70',
    '71',
    '81',
    '82',
    '83',
    '84',
    '85',
    '57',
    '58',
    '59',
    '64',
  ];

  // opマスタを配列に変換
  const opList = Object.keys(OP_SEARCH_ITEMS).map(
    (key) => OP_SEARCH_ITEMS[key]
  );

  // 選択中のこだわりidのindexNoを取得
  const basicOp = op
    ?.map(Number)
    .sort((a, b) => a - b)
    .pop();
  const indNo = opList.findIndex((item) => item.id == basicOp);

  const removeOpList: string[] = [];
  // indexNoとなるop以外の選択中のopを除外リストに入れる（こだわり2軸op×op用）
  const removeOp = op
    ?.map(Number)
    .sort((a, b) => a - b)
    .shift();
  if (removeOp) removeOpList.push(removeOp.toString());
  // 選択中のopに雇用形態を含むとき、他の雇用形態を除外リストに入れる
  const removeEmp = EMPLOYMENT.list.map((item) => item.id.split(',')).flat();
  if (op?.filter((x) => removeEmp.includes(x)).length)
    removeOpList.push(...removeEmp);

  // indexNoを基準にこだわりリストを2分割し、前後を入れ替えてくっつける
  const firstHalfOpList = opList.slice(0, indNo);
  const secondHalfOpList = opList.slice(indNo + 1);
  const replacedOpList = secondHalfOpList.concat(firstHalfOpList);

  // opマスタから、指定のOPを除外したリストを作る
  const removedOpList = replacedOpList.filter(
    (item) => !removeIdList.includes(item.id) && !removeOpList.includes(item.id)
  );

  // 並び替えたこだわりリストの10個目までを取得して返却する
  const additionalOpList = removedOpList.slice(0, 10);

  return additionalOpList.map((item) => [item.id, item.name]);
};

/**
 * op以外のこだわり（ha,ne,es）が選択されているかどうかを確認する処理
 * ※ op以外のこだわり系URL（ha,ne,es）の場合、「ほかのこだわり条件で探す」のURLは掛け合わせをせずopだけのURLを作成する
 *    正：DodaFront/View/JobSearchList/j_oc__01L/-op__17/-preBtn__3/
 *    誤：DodaFront/View/JobSearchList/j_oc__01L/-op__17/-ne__1/-preBtn__3/
 * @param query
 * @return op以外で選択中のこだわり名
 */
export const checkSelectedKodawariName = (
  query: AppQueryParams
): keyof typeof SEARCH_TYPE | undefined => {
  const selectedKodawariName = Object.keys(SEARCH_TYPE).find((key) => {
    const value = query[key.toLowerCase()];
    return value && ['HA', 'NE', 'ES'].includes(key);
  });

  return selectedKodawariName ? SEARCH_TYPE[selectedKodawariName] : undefined;
};

/**
 * 資格マスタを成形する関数
 * @param qualificationId 選択中の資格ID
 * @param qualifications 資格リスト
 * @returns 資格大分類ID
 * @returns 同じ資格大分類に所属する資格リスト
 * @returns 資格大分類名
 * @returns 資格リストを[id,name]に変換したもの
 * @returns 資格リストを{id: '資格ID', existsJobs: '求人有無'}に変換したもの
 */
export const createQcData = (
  qualificationId: string,
  qualifications: QualificationResponse[]
) => {
  // 同じ大分類に所属する資格リスト
  const sameCategoryQcList = qualifications.find((item) =>
    item.qualifications?.find((qc) => qc.id === qualificationId)
  );
  // 選択中の資格が属する大分類名を取得
  const qcLName = sameCategoryQcList?.name;
  // リストから選択中の資格名を取得
  const selectedQcName = sameCategoryQcList?.qualifications?.find(
    (item) => item.id === qualificationId
  )?.name;
  // 資格リストを[id,name]に変換する ※優先度の高い資格のみをリンクにするため「isPriority = true」で絞り込む
  const qcData = sameCategoryQcList?.qualifications
    ?.filter((item) => !!item.isPriority)
    .map((item) => [item.id, item.name]) as string[][];
  // 資格リストを{id: '資格ID', existsJobs: '求人有無(0 or 1)'}に変換する
  const qcFacetData = sameCategoryQcList?.qualifications?.map((item) => {
    const existJobs = item.existsJobs ? 1 : 0;
    return { id: item.id, count: existJobs };
  }) as JobCountDetailResponse[];

  return {
    sameCategoryQcList,
    qcLName,
    selectedQcName,
    qcData,
    qcFacetData,
  };
};

/**
 * 『他資格 × 選択中の条件』…の件数を取得し
 * {id: '資格ID', count: number}[]; の形になるように成形していく
 * @param query
 * @param searchfacetId
 * @param qcList
 * @param facetName
 */
export const getSearchFacetCountForQcMulti = async (
  searchfacetId: string,
  qcList: QualificationResponse | undefined,
  facetName: FacetName
) => {
  // 『他資格 × hoge』のリンクは優先度の高い資格のみ(20件程度)が生成対象なので「isPriority = true」で絞り込む
  const qualificationIdList = qcList?.qualifications
    ?.filter((item) => !!item.isPriority)
    .flatMap((item) => item.id)
    .filter(nonNullable);

  if (!qualificationIdList) return undefined;

  // 資格IDをベースにファセット検索
  const searchFacetCount = qualificationIdList
    ?.map(async (id) => {
      const qcFacetList = await getJobCount({
        qualificationId: id,
      });
      // 資格IDとsearchfacetIdを掛け合わせた件数を抽出
      const targetFacetCount = qcFacetList[facetName]?.find(
        (item) => item.id === searchfacetId
      )?.count;

      // {id: '資格ID', count: number}[] になるように直す ※idが全て同じ（searchfacetId）になってしまうため
      return { id, count: targetFacetCount || 0 };
    })
    .filter(nonNullable);
  const result = await Promise.all(searchFacetCount);
  return result;
};

/**
 * ドリルダウンの種別を判定する
 * @param query リクエストクエロ
 * @param type 生成するドリルダウンの種別
 * @param forceFlg 強制表示フラグ
 * @return ドリルダウンの種別
 */
export const selectDrillDownType = (
  query: AppQueryParams,
  type: keyof typeof SEARCH_TYPE,
  forceFlg: boolean
) => {
  const { ind, ar, pr, ci, wa, oc } = query;
  if (isNotEmptyAndNotComma(ind) || (forceFlg && type === SEARCH_TYPE.IND)) {
    // ドリルダウン種別が業種、または強制表示フラグがtrueで業種SEOを表示する場合
    return DODA_SEARCH_RESULT_LEFTPANEL_IND;
  }
  if (
    // 勤務地に属するパラメータ(ar, pr, ci, wa)のいずれか1つ以上が設定されているか判定
    isNotEmptyAndNotComma(ar) ||
    isNotEmptyAndNotComma(pr) ||
    isNotEmptyAndNotComma(ci) ||
    isNotEmptyAndNotComma(wa) ||
    (forceFlg &&
      (type === SEARCH_TYPE.AR ||
        type === SEARCH_TYPE.PR ||
        type === SEARCH_TYPE.CI ||
        type === SEARCH_TYPE.WA))
  ) {
    // ドリルダウン種別がエリア、または強制表示フラグがtrueでエリアSEOを表示する場合
    switch (type) {
      case SEARCH_TYPE.IND: {
        return DODA_SEARCH_RESULT_LEFTPANEL_IND;
      }
      case SEARCH_TYPE.AR:
      case SEARCH_TYPE.PR:
      case SEARCH_TYPE.CI:
      case SEARCH_TYPE.WA:
      case SEARCH_TYPE.OC:
      case SEARCH_TYPE.OP:
      default: {
        return DODA_SEARCH_RESULT_LEFTPANEL_AR;
      }
    }
  } else if (
    isNotEmptyAndNotComma(oc) ||
    (forceFlg && type === SEARCH_TYPE.OC)
  ) {
    // ドリルダウン種別が職種、または強制表示フラグがtrueで職種SEOを表示する場合
    switch (type) {
      case SEARCH_TYPE.IND:
        return DODA_SEARCH_RESULT_LEFTPANEL_IND;
      case SEARCH_TYPE.AR:
      case SEARCH_TYPE.PR:
      case SEARCH_TYPE.CI:
      case SEARCH_TYPE.WA:
        return DODA_SEARCH_RESULT_LEFTPANEL_AR;
      case SEARCH_TYPE.OC:
      case SEARCH_TYPE.OP:
      default:
        return DODA_SEARCH_RESULT_LEFTPANEL_OC;
    }
  }
  return NUMERIC_0;
};

/**
 * 下階層、掛け合わせ、同階層（一部）リンクボックスのフラグを設定
 * @param query
 * @param cities
 * @param footerLinkPattern
 * @param isShowIndLowerLevelLinkBoxes
 * @param isShowLocationLowerLevelLinkBoxes
 * @param isShowOcLowerLevelLinkBoxes
 * @param isShowIndLinkBoxes
 * @param isShowLocationLinkBoxes
 * @param isShowOcLinkBoxes
 * @param isShowOpLinkBoxes
 * @param isShowHaLinkBoxes
 * @param isShowAverageAgeLinkBoxes
 * @param isShowEmploymentLinkBoxes
 * @param isShowFixedSalaryLinkBoxes
 * @param isShowIndLinkBoxesForOpMulti
 * @param isShowLocationLinkBoxesForOpMulti
 * @param isShowOcLinkBoxesForOpMulti
 * @param isShowOpThreeLinkBoxesForOpMulti
 * @param isShowHaLinkBoxesForOpMulti
 * @param isShowAverageAgeLinkBoxesForOpMulti
 * @param isShowEmploymentLinkBoxesForOpMulti
 * @param isShowFixedSalaryLinkBoxesForOpMulti
 * @param isShowIndLinkBoxesForMulti
 * @param isShowLocationLinkBoxesForMulti
 * @param isShowOcLinkBoxesForMulti
 * @param isOpMulti
 * @returns
 */
export const setNarrowDownLinkBoxesShowFlg = (
  query: AppQueryParams,
  cities: CityOfSearchModal[],
  footerLinkPattern: FooterLinkPatternResponse,
  isShowIndLowerLevelLinkBoxes: { isShow: boolean; dispTopFlg: boolean },
  isShowLocationLowerLevelLinkBoxes: { isShow: boolean; dispTopFlg: boolean },
  isShowOcLowerLevelLinkBoxes: { isShow: boolean; dispTopFlg: boolean },
  isShowIndLinkBoxes: { isShow: boolean; dispTopFlg: boolean },
  isShowLocationLinkBoxes: { isShow: boolean; dispTopFlg: boolean },
  isShowOcLinkBoxes: { isShow: boolean; dispTopFlg: boolean },
  isShowOpLinkBoxes: { isShow: boolean },
  isShowHaLinkBoxes: { isShow: boolean },
  isShowAverageAgeLinkBoxes: { isShow: boolean },
  isShowEmploymentLinkBoxes: { isShow: boolean },
  isShowFixedSalaryLinkBoxes: { isShow: boolean },
  isShowIndLinkBoxesForOpMulti: { isShow: boolean; dispTopFlg: boolean },
  isShowLocationLinkBoxesForOpMulti: { isShow: boolean; dispTopFlg: boolean },
  isShowOcLinkBoxesForOpMulti: { isShow: boolean; dispTopFlg: boolean },
  isShowOpThreeLinkBoxesForOpMulti: { isShow: boolean },
  isShowHaLinkBoxesForOpMulti: { isShow: boolean },
  isShowAverageAgeLinkBoxesForOpMulti: { isShow: boolean },
  isShowEmploymentLinkBoxesForOpMulti: { isShow: boolean },
  isShowFixedSalaryLinkBoxesForOpMulti: { isShow: boolean },
  isShowIndLinkBoxesForMulti: { isShow: boolean; dispTopFlg: boolean },
  isShowLocationLinkBoxesForMulti: { isShow: boolean; dispTopFlg: boolean },
  isShowOcLinkBoxesForMulti: { isShow: boolean; dispTopFlg: boolean },
  isOpMulti: boolean
) => {
  const { ar, pr, ci, wa, ind, oc, op, ha } = query;

  // opに平均年齢、雇用形態、固定給が含まれるかチェックするためにopを分割しておく。
  const opSlice: string[] = ownerOp(op) || [];

  // 下階層リンク（エリア）〇
  if (
    footerLinkPattern.hasLowerLevelArea &&
    (ar || pr || (ci && getIsWdndcty(ci[0], cities)))
  ) {
    // URLにar,pr,ci（政令指定都市のみ）が存在しない場合、表示できない。
    isShowLocationLowerLevelLinkBoxes.isShow = true;
  }

  // 下階層リンク（業種）〇
  if (
    footerLinkPattern.hasLowerLevelIndustry &&
    ind &&
    ind[0].substring(ind[0].length - 1) === 'L'
  ) {
    // URLにind__*Lが存在しない場合、表示できない。
    isShowIndLowerLevelLinkBoxes.isShow = true;
  }

  // 下階層リンク（職種）〇
  if (
    footerLinkPattern.hasLowerLevelOccupation &&
    oc &&
    (oc[0].substring(oc[0].length - 1) === 'L' ||
      oc[0].substring(oc[0].length - 1) === 'M')
  ) {
    // URLにoc__*L,oc__*Mが存在しない場合、表示できない。
    isShowOcLowerLevelLinkBoxes.isShow = true;
  }

  // 掛け合わせリンク（エリア）〇
  if (footerLinkPattern.hasCombinationArea && !ar && !pr && !ci && !wa) {
    // URLにar,pr,ci,waが存在する場合は、表示できない。
    if (isOpMulti) {
      isShowLocationLinkBoxesForMulti.isShow = true;
      isShowLocationLinkBoxesForMulti.dispTopFlg = true;
    } else {
      isShowLocationLinkBoxes.isShow = true;
      isShowLocationLinkBoxes.dispTopFlg = true;
    }
  }

  // 掛け合わせリンク（エリア、こだわり条件1）〇
  if (
    footerLinkPattern.hasCombinationAreaOption1 &&
    !ar &&
    !pr &&
    !ci &&
    !wa &&
    isOpMulti
  ) {
    // URLにar,pr,ci,waが存在する場合は、表示できない。
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowLocationLinkBoxes.isShow = true;
    isShowLocationLinkBoxes.dispTopFlg = true;
  }

  // 掛け合わせリンク（エリア、こだわり条件2）〇
  if (
    footerLinkPattern.hasCombinationAreaOption2 &&
    !ar &&
    !pr &&
    !ci &&
    !wa &&
    isOpMulti
  ) {
    // URLにar,pr,ci,waが存在する場合は、表示できない。
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowLocationLinkBoxesForOpMulti.isShow = true;
    isShowLocationLinkBoxesForOpMulti.dispTopFlg = true;
  }

  // 掛け合わせリンク（業種）〇
  if (footerLinkPattern.hasCombinationIndustry && !ind) {
    // URLにind__*が存在する場合は、表示できない。
    if (isOpMulti) {
      isShowIndLinkBoxesForMulti.isShow = true;
      isShowIndLinkBoxesForMulti.dispTopFlg = true;
    } else {
      isShowIndLinkBoxes.isShow = true;
      isShowIndLinkBoxes.dispTopFlg = true;
    }
  }

  // 掛け合わせリンク（業種、こだわり条件1）〇
  if (footerLinkPattern.hasCombinationIndustryOption1 && !ind && isOpMulti) {
    // URLにind__*が存在する場合は、表示できない。
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowIndLinkBoxes.isShow = true;
    isShowIndLinkBoxes.dispTopFlg = true;
  }

  // 掛け合わせリンク（業種、こだわり条件2）〇
  if (footerLinkPattern.hasCombinationIndustryOption2 && !ind && isOpMulti) {
    // URLにind__*が存在する場合は、表示できない。
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowIndLinkBoxesForOpMulti.isShow = true;
    isShowIndLinkBoxesForOpMulti.dispTopFlg = true;
  }

  // 掛け合わせリンク（職種）〇
  if (footerLinkPattern.hasCombinationOccupation && !oc) {
    // URLにoc__* が存在する場合は、表示できない。
    if (isOpMulti) {
      isShowOcLinkBoxesForMulti.isShow = true;
      isShowOcLinkBoxesForMulti.dispTopFlg = true;
    } else {
      isShowOcLinkBoxes.isShow = true;
      isShowOcLinkBoxes.dispTopFlg = true;
    }
  }

  // 掛け合わせリンク（職種、こだわり条件1）〇
  if (footerLinkPattern.hasCombinationOccupationOption1 && !oc && isOpMulti) {
    // URLにoc__* が存在する場合は、表示できない。
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowOcLinkBoxes.isShow = true;
    isShowOcLinkBoxes.dispTopFlg = true;
  }

  // 掛け合わせリンク（職種、こだわり条件2）〇
  if (footerLinkPattern.hasCombinationOccupationOption2 && !oc && isOpMulti) {
    // URLにoc__* が存在する場合は、表示できない。
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowOcLinkBoxesForOpMulti.isShow = true;
    isShowOcLinkBoxesForOpMulti.dispTopFlg = true;
  }

  // 掛け合わせリンク（年収）〇
  if (footerLinkPattern.hasCombinationAnnualIncome && !ha) {
    // URLにha__*が存在する場合は、表示できない。
    if (isOpMulti) {
      isShowHaLinkBoxesForOpMulti.isShow = true;
    } else {
      isShowHaLinkBoxes.isShow = true;
    }
  }

  // 掛け合わせリンク（平均年齢）〇
  if (
    footerLinkPattern.hasCombinationAverageAge &&
    !opSlice.includes('82') &&
    !opSlice.includes('83') &&
    !opSlice.includes('84') &&
    !opSlice.includes('85')
  ) {
    // URLにop82,op83,op84,op85が存在する場合は、表示できない。
    if (isOpMulti) {
      isShowAverageAgeLinkBoxesForOpMulti.isShow = true;
    } else {
      isShowAverageAgeLinkBoxes.isShow = true;
    }
  }

  // 掛け合わせリンク（こだわり条件）〇
  if (footerLinkPattern.hasCombinationOption) {
    if (isOpMulti) {
      isShowOpThreeLinkBoxesForOpMulti.isShow = true;
    } else {
      isShowOpLinkBoxes.isShow = true;
    }
  }

  // 掛け合わせリンク（雇用形態）〇
  if (
    footerLinkPattern.hasCombinationEmployment &&
    !opSlice.includes('17') &&
    !opSlice.includes('18') &&
    !opSlice.includes('19')
  ) {
    // URLにop17,op18,op19が存在する場合は、表示できない。
    if (isOpMulti) {
      isShowEmploymentLinkBoxesForOpMulti.isShow = true;
    } else {
      isShowEmploymentLinkBoxes.isShow = true;
    }
  }

  // 掛け合わせリンク（固定給）〇
  if (
    footerLinkPattern.hasCombinationFixedSalary &&
    !opSlice.includes('79') &&
    !opSlice.includes('80')
  ) {
    // URLにop79,op80が存在する場合は、表示できない。
    if (isOpMulti) {
      isShowFixedSalaryLinkBoxesForOpMulti.isShow = true;
    } else {
      isShowFixedSalaryLinkBoxes.isShow = true;
    }
  }
};

/**
 * 同階層リンクボックスのフラグを設定
 * @param query
 * @param footerLinkPattern
 * @param isShowIndLinkBoxes
 * @param isShowLocationLinkBoxes
 * @param isShowOcLinkBoxes
 * @param isShowHaLinkBoxes
 * @param isShowAverageAgeLinkBoxes
 * @param isShowOpLinkBoxes
 * @param isOpTakeOver
 * @param isShowOpLinkBoxesForOpMulti1
 * @param isShowOpLinkBoxesForOpMulti2
 * @param isShowEmploymentLinkBoxes
 * @param isShowFixedSalaryLinkBoxes
 * @param isOpMulti
 * @returns
 */
export const setSameStageLinkBoxesShowFlg = (
  query: AppQueryParams,
  footerLinkPattern: FooterLinkPatternResponse,
  isShowIndLinkBoxes: { isShow: boolean },
  isShowLocationLinkBoxes: { isShow: boolean },
  isShowOcLinkBoxes: { isShow: boolean },
  isShowHaLinkBoxes: { isShow: boolean },
  isShowAverageAgeLinkBoxes: { isShow: boolean },
  isShowOpLinkBoxes: { isShow: boolean },
  isOpTakeOver: { isTakeOver: boolean },
  isShowOpLinkBoxesForOpMulti1: { isShow: boolean },
  isShowOpLinkBoxesForOpMulti2: { isShow: boolean },
  isShowEmploymentLinkBoxes: { isShow: boolean },
  isShowFixedSalaryLinkBoxes: { isShow: boolean },
  isOpMulti: boolean
) => {
  const { ar, pr, ci, wa, ind, oc, op, ha, es, ne } = query;

  // opに平均年齢、雇用形態、固定給が含まれるかチェックするためにopを分割しておく。
  const opSlice: string[] = ownerOp(op) || [];

  // 同階層リンク（エリア）〇
  if (footerLinkPattern.hasOtherArea && (ar || pr || ci || wa)) {
    // URLにar,pr,ci,waが存在しない場合、表示できない。
    isShowLocationLinkBoxes.isShow = true;
  }

  // 同階層リンク（業種）〇
  if (footerLinkPattern.hasOtherIndustry && ind) {
    // URLにind__*が存在しない場合、表示できない。
    isShowIndLinkBoxes.isShow = true;
  }

  // 同階層リンク（職種）〇
  if (footerLinkPattern.hasOtherOccupation && oc) {
    // URLにoc__*が存在しない場合、表示できない。
    isShowOcLinkBoxes.isShow = true;
  }

  // 同階層リンク（年収）〇
  if (footerLinkPattern.hasOtherAnnualIncome && (op || ha || es || ne)) {
    // URLにop,ha,es,neのいずれかが存在しない場合、表示できない。
    isShowHaLinkBoxes.isShow = true;
  }

  // 同階層リンク（社員の平均年齢）〇
  if (
    footerLinkPattern.hasOtherAverageAge &&
    (opSlice.includes('82') ||
      opSlice.includes('83') ||
      opSlice.includes('84') ||
      opSlice.includes('85'))
  ) {
    // op82,op83,op84,op85ではない場合、表示できない。
    isShowAverageAgeLinkBoxes.isShow = true;
  }

  // 同階層リンク（こだわり条件）〇
  if (footerLinkPattern.hasOtherOption && (op || ha || es || ne)) {
    // URLにop,ha,es,neのいずれかが存在しない場合、表示できない。
    isShowOpLinkBoxes.isShow = true;
    if (ar || pr || ci || wa || ind || oc) {
      isOpTakeOver.isTakeOver = true;
    }
  }
  // 同階層リンク（こだわり条件1）〇
  if (footerLinkPattern.hasOtherOption1 && isOpMulti) {
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowOpLinkBoxesForOpMulti1.isShow = true;
  }

  // 同階層リンク（こだわり条件2）〇
  if (footerLinkPattern.hasOtherOption2 && isOpMulti) {
    // OP2軸またはop×ha以外のページでは、マスター上で〇が付いていてもリンクが表示されないようにする。
    isShowOpLinkBoxesForOpMulti2.isShow = true;
  }

  // 同階層リンク（雇用形態）〇
  if (
    footerLinkPattern.hasOtherEmployment &&
    (opSlice.includes('17') || opSlice.includes('18') || opSlice.includes('19'))
  ) {
    // op17,op18,op19ではない場合、表示できない。
    isShowEmploymentLinkBoxes.isShow = true;
  }

  // 同階層リンク（固定給）〇
  if (footerLinkPattern.hasOtherFixedSalary && (op || ha || es || ne)) {
    // URLにop,ha,es,neが存在しない場合、表示できない。
    isShowFixedSalaryLinkBoxes.isShow = true;
  }
};

/**
 * index取得：現在のページがPLPまとめページ対象の場合（単体ページ）
 * @param canonicalUrl 現在のページ（静的url)
 * @returns plpIndex
 */
export const getPlpCommaUnitPageIndex = (canonicalUrl: string | undefined) => {
  const targetUrl = canonicalUrl?.replace(PATTERN_PAGE, '');
  return PLP_COMMA_PAGE_LIST.findIndex((plpItem) => plpItem.url === targetUrl);
};

/**
 * index取得：現在のページがPLPまとめページ対象の場合（まとめページ）
 * @param url 現在のページ（静的url)
 * @returns plpIndex
 */
export const getPlpCommaSummaryPageIndex = (url: string | undefined) => {
  const targetUrl = url?.replace(PATTERN_PAGE, '');
  return PLP_COMMA_PAGE_LIST.findIndex(
    (plpItem) => plpItem.commaPageUrl === targetUrl
  );
};

/**
 * PLPまとめページ関連（単体ページ用）
 * @param plpIndex
 * @param plpSinglePage
 * @param breadCrumbList
 */
export const setBreadCrumbPlp = (
  plpIndex: number,
  plpSinglePage: RegExpMatchArray | null,
  breadCrumbList: BreadCrumb[]
) => {
  if (!plpSinglePage) return;
  // パンくず文言・リンクURL情報格納
  breadCrumbList.push({
    breadCrumbText: PLP_COMMA_PAGE_LIST[plpIndex].commaPageName,
    breadCrumbLink: PLP_COMMA_PAGE_LIST[plpIndex].commaPageUrl,
    splitFlg: false,
  });
};

/**
 * 駅リンクボックスのフラグを設定
 * @param query
 * @param footerLinkPattern
 * @param cities
 * @param isShowStationCityLinkBoxes
 * @param isShowStationPrefectureLinkBoxes
 * @returns
 */
export const setStationLinkBoxesShowFlg = (
  query: AppQueryParams,
  footerLinkPattern: FooterLinkPatternResponse,
  cities: CityOfSearchModal[],
  isShowStationCityLinkBoxes: { isShow: boolean },
  isShowStationPrefectureLinkBoxes: { isShow: boolean }
) => {
  const { ci, wa } = query;

  // 駅リンク（市区町村）〇
  if (
    footerLinkPattern.hasStationCity &&
    ((ci && !getIsWdndcty(ci[0], cities)) || wa)
  ) {
    // URLに市区町村（政令都市以外）,waが存在しない場合、表示できない。
    isShowStationCityLinkBoxes.isShow = true;
  }

  // 駅リンク（都道府県）〇
  if (
    footerLinkPattern.hasStationPrefecture &&
    ((ci && !getIsWdndcty(ci[0], cities)) || wa)
  ) {
    // URLに市区町村（政令都市以外）,waが存在しない場合、表示できない。
    isShowStationPrefectureLinkBoxes.isShow = true;
  }
};

/**
 * 団体コードから市区町村駅リンクボックス作成
 * @param worgcd 団体コード
 * @param query
 * @param cities 市区町村マスタ
 * @param stationList 駅マスタ
 * @returns リンクボックス（市区町村駅）
 */
export const getLinkBoxesByWorgcdForCity = async (
  worgcd: string,
  query: AppQueryParams,
  cities: CityOfSearchModal[],
  stationList: StationResponse[]
) => {
  // 団体コードから市区町村データを取得
  const city = getCityByWorgcd(worgcd, cities);
  if (!city) return undefined;

  // 市区町村から駅リンクボックス取得
  const stLinkBoxByWorgcd = await getStLinkBoxByWorgcd(
    worgcd,
    city.name,
    query,
    stationList
  );

  return [stLinkBoxByWorgcd].filter(nonNullable);
};

/**
 * 市区町村駅リンクボックス作成
 * @param worgcd 団体コード
 * @param cityName
 * @param query
 * @param stationList
 * @returns リンクボックス（駅）
 */
const getStLinkBoxByWorgcd = async (
  worgcd: string,
  cityName: string,
  query: AppQueryParams,
  stationList: StationResponse[]
) => {
  // 市区町村駅mapを取得
  const cityStations = getStationsByWorgcd(worgcd, stationList);

  const searchFacetCount = await getSearchFacetCount({ ci: [worgcd] });

  const linkBox = await getLinkBoxForThree(
    EKI,
    cityStations,
    BLANK,
    cityName,
    QUERY_PARAMS.st,
    BLANK,
    QUERY_PARAMS.preBtn,
    String(DODA_SEARCH_RESULT_LEFTPANEL_AR),
    searchFacetCount.stationList,
    'CI'
  );

  return linkBox;
};

/**
 * 都道府県IDから都道府県名を取得
 * @param prefId 都道府県ID
 * @param areaList
 * @returns 都道府県名
 */
export const getPrefectureByPrefId = (
  prefId: string,
  areaList: AreaListOfSearchModal[]
) => {
  // eslint-disable-next-line no-shadow
  const prefectureList = areaList.flatMap((area) => area.prefectureList);
  const prefName = prefectureList.find((pref) => pref.id === prefId)?.name;
  return prefName;
};

/**
 * 都道府県IDから駅リンクボックス作成
 * @param prefId
 * @param titleType
 * @param areas
 * @param stationList
 * @returns リンクボックス（駅）
 */
const getStLinkBoxByPrefID = async (
  prefId: string,
  titleType: TitleFormatType,
  areas: AreaListOfSearchModal[],
  stationList: StationResponse[]
) => {
  // 都道府県名を取得
  const prefName = getPrefectureByPrefId(prefId, areas);
  if (!prefName) return undefined;

  // 都道府県駅mapを取得
  const stationsByPref = getStationsByPrefId(prefId, stationList);

  const searchFacetCount = await getSearchFacetCount({
    pr: [prefId],
  });

  const linkBox = await getLinkBoxForThree(
    EKI,
    stationsByPref,
    BLANK,
    prefName,
    QUERY_PARAMS.st,
    BLANK,
    QUERY_PARAMS.preBtn,
    String(DODA_SEARCH_RESULT_LEFTPANEL_AR),
    searchFacetCount.stationList,
    titleType
  );
  return linkBox;
};

/**
 * 団体コードから都道府県駅リンクボックス作成
 * @param worgcd 団体コード
 * @param cities 市区町村マスタ
 * @param areas 都道府県マスタ
 * @param stationList 駅マスタ
 * @returns リンクボックス（都道府県駅）
 */
export const getLinkBoxesByWorgcdForPrefecture = async (
  worgcd: string,
  cities: CityOfSearchModal[],
  areas: AreaListOfSearchModal[],
  stationList: StationResponse[]
) => {
  // 団体コードから市区町村データを取得
  const city = getCityByWorgcd(worgcd, cities);
  if (!city) return undefined;

  // 都道府県から駅リンクボックス取得
  const stLinkBoxByPref = await getStLinkBoxByPrefID(
    city.prefId,
    'CI',
    areas,
    stationList
  );

  return [stLinkBoxByPref].filter(nonNullable);
};

/**
 * DODASITE-5171_検索結果一覧こだわり１軸絞り込みボタンABテスト
 * 絞り込みボタン完成部品
 * @param data マスタデータ
 * @param selectedOpId 選択中のop
 * @param pt1 パラメータ名1
 * @param pt2 パラメータ名2
 * @param vl2 パラメータ名2の設定値
 * @param pt3 パラメータ名3
 * @param vl3 パラメータ名3の設定値
 * @param pt4 パラメータ名4
 * @param vl4 パラメータ名4の設定値
 * @param searchFacetCount 求人件数
 * @param categoryName NARROW_DOWN
 * @returns ボタンボックス
 */
export const getButtonBox = (
  data: string[][],
  selectedOpId: string,
  pt1: string,
  pt2: string,
  vl2: string,
  pt3: string,
  vl3: string,
  pt4: string,
  vl4: string,
  searchFacetCount: SearchFacetCount[string],
  categoryName: string
) => {
  const boxButtons = data
    .map(([key, value]) => {
      const prId =
        selectedOpId !== ''
          ? [selectedOpId.split(COMMA_URL_ENCODE), key]
              .filter(Boolean)
              .flat()
              .map(Number)
              .sort((a, b) => a - b)
              .join(COMMA_URL_ENCODE)
          : getPrId(categoryName, key);

      // 件数取得
      const facetCount = searchFacetCount?.find(
        (facet) => facet.id === key
      )?.count;

      if (!facetCount) {
        return null;
      }

      const linkURL = makeStaticUrl(
        { [pt1]: prId, [pt2]: vl2, [pt3]: vl3, [pt4]: vl4 },
        DODA_SITE_URL.jobSearchList
      );

      return {
        buttonText: value,
        buttonURL: linkURL,
        buttonId: key,
      };
    })
    .filter(nonNullable);

  return {
    boxButtons,
  };
};
