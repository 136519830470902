// 検索用の項目定義（以下定義書）
// http://10.225.128.220/svn/itg2_career_docs/trunk/開発資料/14.DODA/20.新基盤/01.画面設計/50.共通/補足資料/補足資料_検索項目マスタマッピング.xls
/**
 * SearchItem
 */
export interface SearchItem {
  id: string;
  name: string;
  seoText?: string;
  masterId?: string;
}
export type SearchItemKey = keyof typeof OP_SEARCH_ITEMS;

// こだわりマスタの内容
// https://doda-git/gitbucket/doda.site/doda-backend/blob/826c374cba0f4702bc25573651d025b8ec834c9c/DodaLibrary/src/main/java/jp/co/inte/doda/common/service/common/SeoUtil.java#L375-L419
export const OP_SEARCH_ITEMS = {
  newArrival: {
    id: '1',
    name: '新着',
  },
  secondTimeGraduate: {
    id: '3',
    name: '第二新卒歓迎',
  },
  foreignCompany: {
    id: '4',
    name: '外資系企業',
  },
  MoreThan120DaysOffAYear: {
    id: '5',
    name: '年間休日120日以上',
  },
  flexibleWork: {
    id: '6',
    name: 'フレックス勤務',
  },
  manager: {
    id: '8',
    name: '管理職・マネジャー',
  },
  regularEmployee: {
    id: '17',
    name: '正社員',
  },
  contractEmployee: {
    id: '18',
    name: '契約社員',
  },
  other: {
    id: '19',
    name: 'その他（FCオーナー・業務委託など）',
  },
  deadline: {
    id: '20',
    name: '締め切間近',
  },
  english: {
    id: '21',
    name: '英語を活かす',
  },
  noAcademicBackgroundRequired: {
    id: '24',
    name: '学歴不問',
  },
  noTransfer: {
    id: '27',
    name: '転勤なし（勤務地限定）',
  },
  freeDressCode: {
    id: '32',
    name: '服装自由',
  },
  femaleThriving: {
    id: '33',
    name: '女性活躍',
  },
  companyHousingAndEentSubsidySystem: {
    id: '44',
    name: '社宅・家賃補助制度',
  },
  qualificationAcquisitionSupportSystem: {
    id: '45',
    name: '資格取得支援制度',
  },
  fullTrainingSystem: {
    id: '46',
    name: '研修制度充実',
  },
  listedCompany: {
    id: '47',
    name: '上場企業',
  },
  employsManyPeople: {
    id: '48',
    name: '採用人数5名以上',
  },
  chinese: {
    id: '49',
    name: '中国語を活かす',
  },
  commutingByCar: {
    id: '55',
    name: '自動車通勤OK',
  },
  retirementBenefitPlan: {
    id: '56',
    name: '退職金制度',
  },
  littleOvertime: {
    id: '60',
    name: '残業20時間未満',
  },
  childcareAndChildcareSupportSystem: {
    id: '65',
    name: '育児・託児支援制度',
  },
  expert: {
    id: '67',
    name: '社会人経験10年以上歓迎',
  },
  fiveDayWorkweekSystem: {
    id: '69',
    name: '完全週休2日制',
  },
  inexperiencedOccupationWelcome: {
    id: '70',
    name: '職種未経験歓迎',
  },
  IndustryInexperiencedWelcome: {
    id: '71',
    name: '業種未経験歓迎',
  },
  holiday: {
    id: '72',
    name: '土日祝休み',
  },
  consecutiveVacations: {
    id: '73',
    name: '5日以上の連続休暇OK',
  },
  noOvertime: {
    id: '74',
    name: '原則定時退社',
  },
  maternityAndChildcareLeave: {
    id: '75',
    name: '産休・育休取得実績あり',
  },
  urgentRecruit: {
    id: '76',
    name: '急募！入社まで1カ月',
  },
  fewerRetirees: {
    id: '77',
    name: '定着率95％以上',
  },
  overseasBusinessTrip: {
    id: '78',
    name: '海外出張あり',
  },
  salaryOf250kYenMore: {
    id: '79',
    name: '固定給25万円以上',
  },
  salaryOf350kYenMore: {
    id: '80',
    name: '固定給35万円以上',
  },
  UIturnSupport: {
    id: '81',
    name: 'U・Iターン支援あり',
  },
  twenties: {
    id: '82',
    name: '20代',
    seoText: '社員の平均年齢20代',
  },
  thirties: {
    id: '83',
    name: '30代',
    seoText: '社員の平均年齢30代',
  },
  forties: {
    id: '84',
    name: '40代',
    seoText: '社員の平均年齢40代',
  },
  overFifties: {
    id: '85',
    name: '50代以上',
    seoText: '社員の平均年齢50代以上',
  },
  noChangeInJobTitle: {
    id: '86',
    name: '入社後の職種変更なし',
  },
  temporaryEmployee: {
    id: '57',
    name: '派遣社員',
  },
  introductionDispatching: {
    id: '58',
    name: '紹介予定派遣',
  },
  subcontracting: {
    id: '59',
    name: '業務委託',
  },
  FCOwner: {
    id: '64',
    name: 'FCオーナー',
  },
} as const;

export const HA_SEARCH_ITEMS = [
  {
    id: '0',
    name: '未設定',
    seoText: '未設定',
  },
  {
    id: '20',
    name: '200万円',
    seoText: '年収200万円～',
  },
  {
    id: '25',
    name: '250万円',
    seoText: '年収250万円～',
  },
  {
    id: '30',
    name: '300万円',
    seoText: '年収300万円～',
  },
  {
    id: '35',
    name: '350万円',
    seoText: '年収350万円～',
  },
  {
    id: '40',
    name: '400万円',
    seoText: '年収400万円～',
  },
  {
    id: '45',
    name: '450万円',
    seoText: '年収450万円～',
  },
  {
    id: '50',
    name: '500万円',
    seoText: '年収500万円～',
  },
  {
    id: '55',
    name: '550万円',
    seoText: '年収550万円～',
  },
  {
    id: '60',
    name: '600万円',
    seoText: '年収600万円～',
  },
  {
    id: '65',
    name: '650万円',
    seoText: '年収650万円～',
  },
  {
    id: '70',
    name: '700万円',
    seoText: '年収700万円～',
  },
  {
    id: '80',
    name: '800万円',
    seoText: '年収800万円～',
  },
  {
    id: '90',
    name: '900万円',
    seoText: '年収900万円～',
  },
  {
    id: '100',
    name: '1000万円',
    seoText: '年収1000万円～',
  },
] as const;

export const HA_MASTER_ITEMS_LOWER = [
  {
    id: '0',
    masterId: '',
    name: '未設定',
  },
  {
    id: '20',
    masterId: '151',
    name: '希望年収200万円～',
  },
  {
    id: '25',
    masterId: '152',
    name: '希望年収250万円～',
  },
  {
    id: '30',
    masterId: '153',
    name: '希望年収300万円～',
  },
  {
    id: '35',
    masterId: '154',
    name: '希望年収350万円～',
  },
  {
    id: '40',
    masterId: '155',
    name: '希望年収400万円～',
  },
  {
    id: '45',
    masterId: '156',
    name: '希望年収450万円～',
  },
  {
    id: '50',
    masterId: '157',
    name: '希望年収500万円～',
  },
  {
    id: '55',
    masterId: '158',
    name: '希望年収550万円～',
  },
  {
    id: '60',
    masterId: '159',
    name: '希望年収600万円～',
  },
  {
    id: '65',
    masterId: '160',
    name: '希望年収650万円～',
  },
  {
    id: '70',
    masterId: '161',
    name: '希望年収700万円～',
  },
  {
    id: '80',
    masterId: '162',
    name: '希望年収800万円～',
  },
  {
    id: '90',
    masterId: '163',
    name: '希望年収900万円～',
  },
  {
    id: '100',
    masterId: '164',
    name: '希望年収1000万円～',
  },
] as const;

export const HA_MASTER_ITEMS_UPPER = [
  {
    id: '0',
    masterId: '',
    name: '未設定',
  },
  {
    id: '20',
    masterId: '165',
    name: '希望年収200万円以下',
  },
  {
    id: '25',
    masterId: '166',
    name: '希望年収250万円以下',
  },
  {
    id: '30',
    masterId: '167',
    name: '希望年収300万円以下',
  },
  {
    id: '35',
    masterId: '168',
    name: '希望年収350万円以下',
  },
  {
    id: '40',
    masterId: '169',
    name: '希望年収400万円以下',
  },
  {
    id: '45',
    masterId: '170',
    name: '希望年収450万円以下',
  },
  {
    id: '50',
    masterId: '171',
    name: '希望年収500万円以下',
  },
  {
    id: '55',
    masterId: '172',
    name: '希望年収550万円以下',
  },
  {
    id: '60',
    masterId: '173',
    name: '希望年収600万円以下',
  },
  {
    id: '65',
    masterId: '174',
    name: '希望年収650万円以下',
  },
  {
    id: '70',
    masterId: '175',
    name: '希望年収700万円以下',
  },
  {
    id: '80',
    masterId: '176',
    name: '希望年収800万円以下',
  },
  {
    id: '90',
    masterId: '177',
    name: '希望年収900万円以下',
  },
  {
    id: '100',
    masterId: '178',
    name: '希望年収1000万円以下',
  },
] as const;

export const NE_SEARCH_ITEMS: SearchItem[] = [
  {
    id: '1',
    masterId: '179',
    name: '～10名',
    seoText: '従業員数～10名',
  },
  {
    id: '2',
    masterId: '180',
    name: '11～100名',
    seoText: '従業員数11～100名',
  },
  {
    id: '3',
    masterId: '181',
    name: '101～1000名',
    seoText: '従業員数101～1000名',
  },
  {
    id: '4',
    masterId: '182',
    name: '1001名～',
    seoText: '従業員数1001名～',
  },
];

export const ES_SEARCH_ITEMS: SearchItem[] = [
  {
    id: '1',
    masterId: '183',
    name: '～1年',
    seoText: '設立～1年',
  },
  {
    id: '2',
    masterId: '184',
    name: '2～10年',
    seoText: '設立2～10年',
  },
  {
    id: '3',
    masterId: '185',
    name: '11～50年',
    seoText: '設立11～50年',
  },
  {
    id: '4',
    masterId: '186',
    name: '51年～',
    seoText: '設立51年～',
  },
];
export const TP_SEARCH_ITEMS = {
  agentServices: {
    id: '0',
    name: 'エージェントサービスの求人のみ',
  },
  directApplication: {
    id: '2',
    name: '企業に直接応募する求人のみ',
  },
};
