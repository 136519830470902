import { useEffect } from 'react';
import { scrollToElementId } from '@doda/common/src/utils/animationUtil';

/**
 * Custom hook to scroll the page to the element with the specified ID, optionally adding an offset.
 *
 * @param {number} offset - The offset to add to the scroll position.
 */
export const useScrollToHash = (offset = 0) => {
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    let timeoutId: NodeJS.Timeout | null = null;

    // Only run this effect on the client-side (ensuring window is available)
    if (typeof window === 'undefined') return undefined;
    const hash = window.location.hash.substring(1);

    // If there is no hash, don't do anything
    if (!hash) return undefined;

    const element = document.getElementById(hash);
    if (element) {
      // Element is already present, scroll to it
      scrollToElementId(hash, offset);
    } else {
      // Polling mechanism to check for the element
      intervalId = setInterval(() => {
        const el = document.getElementById(hash);
        if (el) {
          scrollToElementId(hash, offset);
          if (intervalId) clearInterval(intervalId); // Stop checking once the element is found
        }
      }, 200);

      // Set a timeout to stop polling after 1 second
      timeoutId = setTimeout(() => {
        if (intervalId) clearInterval(intervalId); // Stop the interval after timeout
      }, 1000);
    }

    // Cleanup function to clear interval and timeout if they were set
    return () => {
      if (intervalId) clearInterval(intervalId);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [offset]); // Run the effect when offset changes
};
