import { FC } from 'react';
import { reducer } from '@doda/pc/src/pages/DodaFront/View/Top/redux/store';
import { createReduxWrapper } from '@doda/common/src/redux/utils/createReduxWrapper';
import { HEADER_NAV_MENU, HEADER_PAGES } from 'constants/headerNavMenu';
import { Provider } from 'react-redux';
import { createOptimizedContext } from '@doda/common/src/utils/createOptimizedContext';
import { initServerSideProps } from './DodaFront/View/Top/initServerSideProps';
import {
  TopScreen,
  TopScreenProps,
} from './DodaFront/View/Top/screens/TopScreen';
import { BasicLayout } from '../components/layouts/BasicLayout';
import { DesiredCondModal } from './DodaFront/View/Top/components/DesiredCondModal';

const [ModalProvider, useModalState, useSetModal] =
  createOptimizedContext(false);

export { useModalState, useSetModal };

const Index: FC<TopScreenProps> = (pageProps) => {
  const { store, props } = wrapper.useWrappedStore(pageProps);
  const {
    shouldShowResumePopup,
    announcementList,
    keyVisual,
    totalCountWithPrivateJobs,
    rightSideNavigationTopBanners,
    popularJobsFeature,
    eventSeminars,
    rightSideNavigationCenterBanners,
    newArrivalsFocusedContents,
    noticesFromDoda,
    businessClientInformation,
    rightSideNavigationBottomBanners,
    pr,
    searchMenuLogOut,
    recommendationForYou,
    diagnosticDocumentTool,
    popularServices,
    relatedWebsites,
    suggestedJobs,
    searchMenuLogIn,
    jobTypeList,
    isViewModePC,
    isShowPasswordNotification,
    logoutRecommends,
    loginRecommends,
    isPopUpPasswordNotification,
    publicJobCount,
    publicationDate,
    applicationUplift,
    premiumOffer,
    googleAnalyticsId,
    topB4rTsuukaRecommendType,
  } = props.pageProps;

  return (
    <Provider store={store}>
      <ModalProvider>
        <BasicLayout
          currentPage={HEADER_PAGES.TOP}
          shouldShowResumePopup={shouldShowResumePopup}
          selectedNavItem={HEADER_NAV_MENU.SEARCH}
          isViewModePC={isViewModePC}
        >
          <TopScreen
            announcementList={announcementList}
            keyVisual={keyVisual}
            totalCountWithPrivateJobs={totalCountWithPrivateJobs}
            rightSideNavigationTopBanners={rightSideNavigationTopBanners}
            popularJobsFeature={popularJobsFeature}
            eventSeminars={eventSeminars}
            rightSideNavigationCenterBanners={rightSideNavigationCenterBanners}
            newArrivalsFocusedContents={newArrivalsFocusedContents}
            noticesFromDoda={noticesFromDoda}
            businessClientInformation={businessClientInformation}
            rightSideNavigationBottomBanners={rightSideNavigationBottomBanners}
            pr={pr}
            searchMenuLogOut={searchMenuLogOut}
            recommendationForYou={recommendationForYou}
            diagnosticDocumentTool={diagnosticDocumentTool}
            popularServices={popularServices}
            relatedWebsites={relatedWebsites}
            suggestedJobs={suggestedJobs}
            searchMenuLogIn={searchMenuLogIn}
            jobTypeList={jobTypeList}
            isShowPasswordNotification={isShowPasswordNotification}
            logoutRecommends={logoutRecommends}
            loginRecommends={loginRecommends}
            isPopUpPasswordNotification={isPopUpPasswordNotification}
            publicJobCount={publicJobCount}
            publicationDate={publicationDate}
            applicationUplift={applicationUplift}
            premiumOffer={premiumOffer}
            googleAnalyticsId={googleAnalyticsId}
            topB4rTsuukaRecommendType={topB4rTsuukaRecommendType}
          />
        </BasicLayout>
        <DesiredCondModal />
      </ModalProvider>
    </Provider>
  );
};

const wrapper = createReduxWrapper(reducer);

export const getServerSideProps =
  wrapper.getServerSideProps(initServerSideProps);

export default Index;
