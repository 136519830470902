/**
 * スクロールアニメーション
 * @param {number} endY スクロール先位置
 */
export const smoothScroll = (endY: number): void => {
  // スクロール開始地点
  const startY = window.pageYOffset;
  // 経過時間
  let timeLapsed = 0;
  // 開始時間
  let startstamp = 0;
  // 1000pxスクロールの時間
  const scrollTime = 100;

  const distanceY = endY - startY;
  const duration = Math.abs((distanceY / 1000) * scrollTime);

  requestAnimationFrame(function animate(timestamp) {
    if (startstamp === 0) {
      startstamp = timestamp;
    }
    timeLapsed += timestamp - startstamp;
    // 経過時間 / 完了時間からスクロール割合を算出。(0 <= percentage <= 1)
    const percentage = Math.max(
      0,
      Math.min(1, duration === 0 ? 0 : timeLapsed / duration)
    );
    // スクロール割合から現在地を算出。
    const positionY = Math.floor(startY + distanceY * percentage);

    window.scroll(0, positionY);

    if (positionY !== endY) {
      requestAnimationFrame(animate);
      startstamp = timestamp;
    }
  });
};

/**
 * フェードイン
 * @param {Element} element
 * @param {number} duration - 時間（milliseconds）
 * @param {Function} callback - コールバック処理
 */
export function fadeIn(
  element: HTMLElement,
  duration: number,
  callback: VoidFunction
): void {
  // 要素存在チェック
  if (getComputedStyle(element).display !== 'none') {
    return;
  }

  // スタイル設定
  element.style.display = 'block';
  element.style.opacity = '0';

  // フェードイン
  let start = 0;
  requestAnimationFrame(function tick(timestamp) {
    if (!start) {
      start = timestamp;
    }

    // イージング(liner)
    const easing = (timestamp - start) / duration;

    // 最小値取得
    element.style.opacity = `${Math.min(easing, 1)}`;

    if (easing < 1) {
      // 再帰
      requestAnimationFrame(tick);
    } else {
      // スタイル表示
      element.style.opacity = '1';

      // コールバック
      if (callback) {
        callback();
      }
    }
  });
}

/**
 * フェードアウト
 * @param {Element} element
 * @param {number} duration - 時間（milliseconds）
 * @param {Function} callback
 */
export function fadeOut(
  element: HTMLElement,
  duration: number,
  callback?: VoidFunction
): void {
  // 要素存在チェック
  if (getComputedStyle(element).display === 'none') {
    return;
  }

  // スタイル設定
  element.style.display = 'block';
  element.style.opacity = '1';

  // フェードイン
  let start = 0;
  requestAnimationFrame(function tick(timestamp) {
    if (!start) {
      start = timestamp;
    }

    // イージング(liner)
    const easing = (timestamp - start) / duration;

    // 最小値取得
    element.style.opacity = `${1 - Math.min(easing, 1)}`;

    if (easing < 1) {
      // 再帰
      requestAnimationFrame(tick);
    } else {
      // スタイル表示
      element.style.opacity = '0';
      element.style.display = 'none';
      // コールバック
      if (callback) {
        callback();
      }
    }
  });
}

/**
 * Smoothly scrolls the page to a target vertical position within a specified duration.
 *
 * @param {number} targetY - The target Y-coordinate to scroll to (in pixels).
 * @param {number} duration - The duration (in milliseconds) to complete the scroll.
 */
export const smoothScrollWithTime = (targetY: number, duration: number) => {
  const startY = window.scrollY;
  const distance = targetY - startY;
  const startTime = performance.now();

  const scroll = (currentTime: number) => {
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const scrollPosition = startY + distance * progress;

    window.scrollTo(0, scrollPosition);

    if (progress < 1) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};

/**
 * Scrolls the page to the element with the specified ID, taking into account the provided offset.
 *
 * This function calculates the top position of the element relative to the document's scroll position,
 * then smoothly scrolls to that position, adding the specified offset (e.g., for a fixed header).
 *
 * @param {string} id - The ID of the element to scroll to.
 * @param {number} offset - The offset to add to the scroll position, often used for things like fixed headers.
 */
export const scrollToElementId = (id: string, offset: number) => {
  const element = document.getElementById(id);
  if (element) {
    // Scroll the element into view with an offset
    const elementTop = element.getBoundingClientRect().top + window.scrollY;
    smoothScrollWithTime(elementTop + offset, 200);
  }
};
