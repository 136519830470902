import { JobCountResponse } from '@doda/api-client/aspida/@types';
import { AppQueryParams } from '../../../constants/queryParams';
import { getJobCount } from '../../../services/getJobCountAPI';
import { opToJobCountResponsKV } from '../../optionQueryUtil';
import { nonNullable } from '../../typeGuard';
import { createJobCountRequestParams } from '../../../logics/createJobCountRequestParams';

/**
 * 求人件数一括取得（ファセット検索）
 * @param query
 * @returns
 */
export const getSearchFacetCount = async (query: AppQueryParams) => {
  const params = createJobCountRequestParams(query);
  const result = await getJobCount(params);
  return result;
};

/**
 * opとJobCountResponseのKey/Valueを紐づけてidとcountを返す
 * @param searchFacetCount
 * @returns
 */
export const convOpSearchFacetCount = (
  master: string[][],
  searchFacetCount: JobCountResponse
) => {
  return master
    .map(([id]) => {
      const requestQuery = opToJobCountResponsKV(id);
      if (!requestQuery) return undefined;

      const facetName = requestQuery.key;
      const count = searchFacetCount[facetName]?.find(
        (item) => item.id === requestQuery.value
      )?.count;

      if (!count) return undefined;

      return { id, count };
    })
    .filter(nonNullable);
};

/**
 * DODASITE-5171_検索結果一覧こだわり１軸絞り込みボタンABテスト
 * opとJobCountResponseのKey/Valueを紐づけてidとcountを返す
 * @param master
 * @param searchFacetCount
 * @returns
 */
export const convOpSearchFacetCountForSeoButton = (
  master: string[][],
  searchFacetCount: JobCountResponse
) => {
  return master
    .map(([id]) => {
      if (id === '1') {
        const count = searchFacetCount.newArrivalCount;
        if (!count) return undefined;
        return { id, count };
      }

      const requestQuery = opToJobCountResponsKV(id);
      if (!requestQuery) return undefined;

      const facetName = requestQuery.key;
      const count = searchFacetCount[facetName]?.find(
        (item) => item.id === requestQuery.value
      )?.count;

      if (!count) return undefined;

      return { id, count };
    })
    .filter(nonNullable);
};
