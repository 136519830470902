import { useSelector } from 'react-redux';
import {
  RecommendSlider,
  RecommendSliderProps,
} from 'components/RecommendSlider';
import { formatString } from '@doda/common/src/utils/stringUtil';
import { adobeAnalyticsScriptsSelector } from '@doda/common/src/redux/features/analytics';
import { useEffect } from 'react';
import { tl } from '@doda/common/src/utils/adobeAnalyticsUtil';
import { RecommendPanelTop, TopB4rTsuukaRecommendType } from './types';
import {
  BOOK_MARK_URL,
  PERSONALIZED_RECOMMEND_LINK_URL,
  RECOMMEND_LINK_URL,
} from './constants';
import styles from './index.module.scss';

/**
 * レコメンドの取得データ
 * @property recommendJobList レコメンド求人リスト
 * @property handleClickThumbnail サムネイルクリック時に発火する関数
 * @property handleClickCardDetail カードタイトルクリック時に発火する関数
 * @property handleClickFavorite 気になるボタンクリック時に発火する関数
 * @property queryParamOnClick クリック時に付与するクエリパラメータ
 */
interface Props {
  recommendJobList: RecommendPanelTop;
  handleClickThumbnail: RecommendSliderProps['onClickThumbnail'];
  handleClickCardDetail: RecommendSliderProps['onClickCardDetail'];
  handleClickFavorite: RecommendSliderProps['onClickFavorite'];
  queryParamOnClick: string;
  // DODASITE-788_B4R×通過スコアモデル_ABテスト
  topB4rTsuukaRecommendType: TopB4rTsuukaRecommendType;
}

export const RecommendPanelLogIn = ({
  recommendJobList,
  handleClickThumbnail,
  handleClickCardDetail,
  handleClickFavorite,
  queryParamOnClick,
  topB4rTsuukaRecommendType,
}: Props) => {
  const { recommendType, title, results, recommendLinkType, jobCount } =
    recommendJobList;
  const isNewArrival: boolean = recommendType === 'newjob_list';

  // DODASITE-788_B4R×通過スコアモデル_ABテスト計測
  const isAdobeScript = useSelector(adobeAnalyticsScriptsSelector);
  useEffect(() => {
    // NOTE: レコメンドがB4R×通過スコアモデルまたは応募B4Rではない場合計測発火しない
    if (
      recommendType !== 'b4r_cbm_gousei_rerank_recommend' &&
      recommendType !== 'AWS_Recommend_oubo'
    )
      return;

    // NOTE: ABテスト groupNo 失敗時は計測発火しない
    if (topB4rTsuukaRecommendType === 'FAILED' || !isAdobeScript) return;

    const LINK_NAME = {
      ORIGINAL: 'ori_b4rtsuuka_oubo',
      TEST: 'test_b4rtsuuka_oubo',
    } as const;

    tl(LINK_NAME[topB4rTsuukaRecommendType], {
      pageName: 'PC_ログイン後トップ',
    });

    // NOTE: 計測発火はisAdobeScriptの変更のみ検知する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdobeScript]);

  // NOTE: パーソナライズレコメンド以外は、1件以下の場合非表示とするため
  const shouldShowRecommends =
    recommendLinkType === 'personalized_area' || results.length > 1;

  if (!shouldShowRecommends) return null;

  const href =
    recommendLinkType === 'personalized_area'
      ? PERSONALIZED_RECOMMEND_LINK_URL[recommendType]
      : RECOMMEND_LINK_URL[recommendLinkType];

  return (
    <div
      className={styles.recommendPanelLogIn__slider}
      id={isNewArrival ? 'newArrival01' : undefined}
    >
      <RecommendSlider
        recommendList={results}
        title={title}
        recommendType={recommendType}
        onClickSaved={undefined}
        bookmarkListUrl={formatString(BOOK_MARK_URL, ['login'])}
        saveBookmarkRequestBody={{ device: 'PC' }}
        isSummaryBottom
        href={href}
        onClickThumbnail={handleClickThumbnail}
        onClickCardDetail={handleClickCardDetail}
        onClickFavorite={handleClickFavorite}
        isNewArrival={isNewArrival}
        jobCount={jobCount}
        queryParamOnClick={queryParamOnClick}
      />
    </div>
  );
};
