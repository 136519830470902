import { datadogLogs } from '@datadog/browser-logs';
import { isAppProd } from '../../utils/envUtil';

/**
 * Datadog Browser Logs Tag
 */
interface Props {
  isSmartphone: boolean;
}

/**
 * initDatadogBrowserLogs
 * @param props.isSmartphone
 */
export const initDatadogBrowserLogs = ({ isSmartphone }: Props) => {
  // DOC: 振り分け設定 https://tecghes.persol-career.co.jp/pcadoda/doda-furiwake-server-setting/blob/master/%E6%9C%AC%E7%95%AA/etc/httpd/conf/extra/httpd-vhosts_Live.conf
  const service = isSmartphone
    ? 'doda-site-spa-sp-browser'
    : 'doda-site-spa-pc-browser';

  const clientToken = isAppProd
    ? 'pub551eaaf718221f12afaeaf34021e9570'
    : 'pub1788550291f96e268d77ec5bd708a8b2';

  const env = isAppProd ? 'prd' : 'stg';

  datadogLogs.init({
    clientToken,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env,
    service,
  });
};
