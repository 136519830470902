import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_qjtpnj } from './api/temp/searchcriteria';
import type { Methods as Methods_zjar3e } from './api/v1/abtests/_projectId@string';
import type { Methods as Methods_4k2f96 } from './api/v1/abtests/authenticated/_projectId@string';
import type { Methods as Methods_zx81u } from './api/v1/area';
import type { Methods as Methods_76f895 } from './api/v1/careerAdvisor/corporateClients/_corporateClientId@string';
import type { Methods as Methods_1bte5gt } from './api/v1/careerAdvisor/corporateClients/_corporateClientId@string/pageLink';
import type { Methods as Methods_cg4zph } from './api/v1/careerAdvisor/corporateClients/pageList';
import type { Methods as Methods_1a4o39s } from './api/v1/careerAdvisor/jobs';
import type { Methods as Methods_znkox } from './api/v1/careerAdvisor/jobs/_arcsOrderId@number';
import type { Methods as Methods_t0is67 } from './api/v1/careerAdvisor/jobs/application/abandon';
import type { Methods as Methods_1ijkuxd } from './api/v1/careerAdvisor/jobs/bookmark';
import type { Methods as Methods_5cxa8c } from './api/v1/careerAdvisor/jobs/count';
import type { Methods as Methods_1ctwem4 } from './api/v1/careerAdvisor/jobs/markAsRead';
import type { Methods as Methods_ou7n4l } from './api/v1/careerAdvisor/jobs/related/otherJobs';
import type { Methods as Methods_wqu3f2 } from './api/v1/careerAdvisor/jobs/state';
import type { Methods as Methods_v7kqnq } from './api/v1/corporateClients/_corporateClientId@number/publicationPhotos';
import type { Methods as Methods_12orw7k } from './api/v1/counseling/diagnosticquestionnaire/answers/status';
import type { Methods as Methods_1cgypke } from './api/v1/events/_entryId@string';
import type { Methods as Methods_1ar46wa } from './api/v1/featureFlags';
import type { Methods as Methods_13lk0ru } from './api/v1/front/job/search/canonical';
import type { Methods as Methods_8ls0yn } from './api/v1/front/job/search/footerLinkPattern';
import type { Methods as Methods_1nl9x3n } from './api/v1/front/job/search/titlemeta';
import type { Methods as Methods_6lsh3h } from './api/v1/front/jobs/search/label';
import type { Methods as Methods_mweons } from './api/v1/image/company';
import type { Methods as Methods_h9ruy5 } from './api/v1/industry';
import type { Methods as Methods_1if69eo } from './api/v1/job/_jid@string';
import type { Methods as Methods_1cgl7dt } from './api/v1/job/_jid@string/bookmark';
import type { Methods as Methods_tx49zq } from './api/v1/job/_jid@string/clicksCountUp';
import type { Methods as Methods_dxiw0c } from './api/v1/job/_jid@string/markAsRead';
import type { Methods as Methods_1ggb4dr } from './api/v1/job/_jid@string/pvCountUp';
import type { Methods as Methods_1njf7b1 } from './api/v1/job/_jid@string/relationInfoList/_corporateClientId@string';
import type { Methods as Methods_1kgnldy } from './api/v1/jobType';
import type { Methods as Methods_108luin } from './api/v1/jobs';
import type { Methods as Methods_1h3q6m7 } from './api/v1/jobs/corporateJobs';
import type { Methods as Methods_3quz6n } from './api/v1/jobs/count';
import type { Methods as Methods_1hk2ini } from './api/v1/jobs/featured';
import type { Methods as Methods_ipxlww } from './api/v1/jobs/keywords';
import type { Methods as Methods_1nt8tj } from './api/v1/jobs/neararea';
import type { Methods as Methods_ffa726 } from './api/v1/jobs/recommendations/applicationAndBookmark';
import type { Methods as Methods_o81vz4 } from './api/v1/jobs/recommendations/interests';
import type { Methods as Methods_16zdarc } from './api/v1/jobs/topics/_id@string';
import type { Methods as Methods_13ju89s } from './api/v1/jwttest';
import type { Methods as Methods_1ex2g6 } from './api/v1/mail/unsubscribe';
import type { Methods as Methods_1aqc38a } from './api/v1/prePublishedScout/jobs/_prePublishedScoutId@number';
import type { Methods as Methods_9h9im2 } from './api/v1/prePublishedScout/jobs/bookmark';
import type { Methods as Methods_c74r9t } from './api/v1/qualifications';
import type { Methods as Methods_zee37p } from './api/v1/railwayCompany';
import type { Methods as Methods_1arbdhu } from './api/v1/savedSearches';
import type { Methods as Methods_itimew } from './api/v1/savedSearches/_no@string';
import type { Methods as Methods_jr3h7h } from './api/v1/scout/job/_jid@string';
import type { Methods as Methods_1rp3slj } from './api/v1/scout/job/_jid@string/markAsRead';
import type { Methods as Methods_1y73qny } from './api/v1/selectJobs/_selectJobCode@string';
import type { Methods as Methods_1en3614 } from './api/v1/selectJobs/_selectJobCode@string/reference';
import type { Methods as Methods_1lob0jk } from './api/v1/selectJobs/jobs';
import type { Methods as Methods_xnzz7g } from './api/v1/selectJobs/jobs/count';
import type { Methods as Methods_kzqanu } from './api/v1/user/service/usageStatus';
import type { Methods as Methods_r5qu0t } from './api/v1/users';
import type { Methods as Methods_1w5ssoh } from './api/v1/users/integration/dodax';
import type { Methods as Methods_1ihierz } from './api/v1/users/integration/dodax/agent';
import type { Methods as Methods_5gkqkr } from './api/v1/users/notification/password';
import type { Methods as Methods_w9dtta } from './api/v2/industry';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://internal-hydrogen-alb-stg-2046329920.ap-northeast-1.elb.amazonaws.com' : baseURL).replace(/\/$/, '');
  const PATH0 = '/api/temp/searchcriteria';
  const PATH1 = '/api/v1/abtests';
  const PATH2 = '/api/v1/abtests/authenticated';
  const PATH3 = '/api/v1/area';
  const PATH4 = '/api/v1/careerAdvisor/corporateClients';
  const PATH5 = '/pageLink';
  const PATH6 = '/api/v1/careerAdvisor/corporateClients/pageList';
  const PATH7 = '/api/v1/careerAdvisor/jobs';
  const PATH8 = '/api/v1/careerAdvisor/jobs/application/abandon';
  const PATH9 = '/api/v1/careerAdvisor/jobs/bookmark';
  const PATH10 = '/api/v1/careerAdvisor/jobs/count';
  const PATH11 = '/api/v1/careerAdvisor/jobs/markAsRead';
  const PATH12 = '/api/v1/careerAdvisor/jobs/related/otherJobs';
  const PATH13 = '/api/v1/careerAdvisor/jobs/state';
  const PATH14 = '/api/v1/corporateClients';
  const PATH15 = '/publicationPhotos';
  const PATH16 = '/api/v1/counseling/diagnosticquestionnaire/answers/status';
  const PATH17 = '/api/v1/events';
  const PATH18 = '/api/v1/featureFlags';
  const PATH19 = '/api/v1/front/job/search/canonical';
  const PATH20 = '/api/v1/front/job/search/footerLinkPattern';
  const PATH21 = '/api/v1/front/job/search/titlemeta';
  const PATH22 = '/api/v1/front/jobs/search/label';
  const PATH23 = '/api/v1/image/company';
  const PATH24 = '/api/v1/industry';
  const PATH25 = '/api/v1/job';
  const PATH26 = '/bookmark';
  const PATH27 = '/clicksCountUp';
  const PATH28 = '/markAsRead';
  const PATH29 = '/pvCountUp';
  const PATH30 = '/relationInfoList';
  const PATH31 = '/api/v1/jobType';
  const PATH32 = '/api/v1/jobs';
  const PATH33 = '/api/v1/jobs/corporateJobs';
  const PATH34 = '/api/v1/jobs/count';
  const PATH35 = '/api/v1/jobs/featured';
  const PATH36 = '/api/v1/jobs/keywords';
  const PATH37 = '/api/v1/jobs/neararea';
  const PATH38 = '/api/v1/jobs/recommendations/applicationAndBookmark';
  const PATH39 = '/api/v1/jobs/recommendations/interests';
  const PATH40 = '/api/v1/jobs/topics';
  const PATH41 = '/api/v1/jwttest';
  const PATH42 = '/api/v1/mail/unsubscribe';
  const PATH43 = '/api/v1/prePublishedScout/jobs';
  const PATH44 = '/api/v1/prePublishedScout/jobs/bookmark';
  const PATH45 = '/api/v1/qualifications';
  const PATH46 = '/api/v1/railwayCompany';
  const PATH47 = '/api/v1/savedSearches';
  const PATH48 = '/api/v1/scout/job';
  const PATH49 = '/api/v1/selectJobs';
  const PATH50 = '/reference';
  const PATH51 = '/api/v1/selectJobs/jobs';
  const PATH52 = '/api/v1/selectJobs/jobs/count';
  const PATH53 = '/api/v1/user/service/usageStatus';
  const PATH54 = '/api/v1/users';
  const PATH55 = '/api/v1/users/integration/dodax';
  const PATH56 = '/api/v1/users/integration/dodax/agent';
  const PATH57 = '/api/v1/users/notification/password';
  const PATH58 = '/api/v2/industry';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';
  const PATCH = 'PATCH';

  return {
    api: {
      temp: {
        searchcriteria: {
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          post: (option: { body: Methods_qjtpnj['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_qjtpnj['post']['status']>(prefix, PATH0, POST, option).send(),
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          $post: (option: { body: Methods_qjtpnj['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_qjtpnj['post']['status']>(prefix, PATH0, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH0}`,
        },
      },
      v1: {
        abtests: {
          _projectId: (val3: string) => {
            const prefix3 = `${PATH1}/${val3}`;

            return {
              /**
               * 指定した施策ID、割り当てID（指定なければcsp_sessionの候補者ID）を用いてABテスト振分け用のグループNoを取得する
               * @returns 成功
               */
              get: (option?: { query?: Methods_zjar3e['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_zjar3e['get']['resBody'], BasicHeaders, Methods_zjar3e['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した施策ID、割り当てID（指定なければcsp_sessionの候補者ID）を用いてABテスト振分け用のグループNoを取得する
               * @returns 成功
               */
              $get: (option?: { query?: Methods_zjar3e['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_zjar3e['get']['resBody'], BasicHeaders, Methods_zjar3e['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_zjar3e['get']['query'] } | undefined) =>
                `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            };
          },
          authenticated: {
            _projectId: (val4: string) => {
              const prefix4 = `${PATH2}/${val4}`;

              return {
                /**
                 * 指定した施策ID、割り当てID（認証情報内の候補者ID）を用いてABテスト振分け用のグループNoを取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_4k2f96['get']['resBody'], BasicHeaders, Methods_4k2f96['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定した施策ID、割り当てID（認証情報内の候補者ID）を用いてABテスト振分け用のグループNoを取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_4k2f96['get']['resBody'], BasicHeaders, Methods_4k2f96['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
          },
        },
        area: {
          /**
           * 地域・都道府県・市区町村・政令指定都市マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_zx81u['get']['resBody'], BasicHeaders, Methods_zx81u['get']['status']>(prefix, PATH3, GET, option).json(),
          /**
           * 地域・都道府県・市区町村・政令指定都市マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_zx81u['get']['resBody'], BasicHeaders, Methods_zx81u['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH3}`,
        },
        careerAdvisor: {
          corporateClients: {
            _corporateClientId: (val4: string) => {
              const prefix4 = `${PATH4}/${val4}`;

              return {
                pageLink: {
                  /**
                   * 指定された法人顧客IDに該当する、企業ページへの導線情報を取得する
                   * @returns 成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1bte5gt['get']['resBody'], BasicHeaders, Methods_1bte5gt['get']['status']>(prefix, `${prefix4}${PATH5}`, GET, option).json(),
                  /**
                   * 指定された法人顧客IDに該当する、企業ページへの導線情報を取得する
                   * @returns 成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1bte5gt['get']['resBody'], BasicHeaders, Methods_1bte5gt['get']['status']>(prefix, `${prefix4}${PATH5}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}${PATH5}`,
                },
                /**
                 * 指定された法人顧客IDに該当する、法人顧客情報を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_76f895['get']['resBody'], BasicHeaders, Methods_76f895['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定された法人顧客IDに該当する、法人顧客情報を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_76f895['get']['resBody'], BasicHeaders, Methods_76f895['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
            pageList: {
              /**
               * 指定された法人顧客ID(複数)に該当する、企業ページ情報を一覧で取得する
               * @returns 成功
               */
              get: (option: { query: Methods_cg4zph['get']['query'], config?: T | undefined }) =>
                fetch<Methods_cg4zph['get']['resBody'], BasicHeaders, Methods_cg4zph['get']['status']>(prefix, PATH6, GET, option).json(),
              /**
               * 指定された法人顧客ID(複数)に該当する、企業ページ情報を一覧で取得する
               * @returns 成功
               */
              $get: (option: { query: Methods_cg4zph['get']['query'], config?: T | undefined }) =>
                fetch<Methods_cg4zph['get']['resBody'], BasicHeaders, Methods_cg4zph['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_cg4zph['get']['query'] } | undefined) =>
                `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
          },
          jobs: {
            _arcsOrderId: (val4: number) => {
              const prefix4 = `${PATH7}/${val4}`;

              return {
                /**
                 * キャリアアドバイザー紹介求人詳細を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_znkox['get']['resBody'], BasicHeaders, Methods_znkox['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * キャリアアドバイザー紹介求人詳細を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_znkox['get']['resBody'], BasicHeaders, Methods_znkox['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
            application: {
              abandon: {
                /**
                 * キャリアアドバイザー紹介求人の応募離脱情報であるかご落ち情報を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_t0is67['get']['resBody'], BasicHeaders, Methods_t0is67['get']['status']>(prefix, PATH8, GET, option).json(),
                /**
                 * キャリアアドバイザー紹介求人の応募離脱情報であるかご落ち情報を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_t0is67['get']['resBody'], BasicHeaders, Methods_t0is67['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH8}`,
              },
            },
            bookmark: {
              /**
               * キャリアアドバイザー紹介求人の気になる登録を実施する
               * @param option.body - キャリアアドバイザー紹介求人気になる登録リクエストボディ
               */
              post: (option: { body: Methods_1ijkuxd['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ijkuxd['post']['status']>(prefix, PATH9, POST, option).send(),
              /**
               * キャリアアドバイザー紹介求人の気になる登録を実施する
               * @param option.body - キャリアアドバイザー紹介求人気になる登録リクエストボディ
               */
              $post: (option: { body: Methods_1ijkuxd['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ijkuxd['post']['status']>(prefix, PATH9, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH9}`,
            },
            count: {
              /**
               * キャリアアドバイザー紹介求人の件数を取得する
               * @returns 成功
               */
              get: (option?: { query?: Methods_5cxa8c['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_5cxa8c['get']['resBody'], BasicHeaders, Methods_5cxa8c['get']['status']>(prefix, PATH10, GET, option).json(),
              /**
               * キャリアアドバイザー紹介求人の件数を取得する
               * @returns 成功
               */
              $get: (option?: { query?: Methods_5cxa8c['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_5cxa8c['get']['resBody'], BasicHeaders, Methods_5cxa8c['get']['status']>(prefix, PATH10, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_5cxa8c['get']['query'] } | undefined) =>
                `${prefix}${PATH10}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            markAsRead: {
              /**
               * キャリアアドバイザー紹介求人詳細を閲覧済み登録を行う
               * @param option.body - キャリアアドバイザー紹介求人閲覧済み登録リクエストボディ
               */
              post: (option: { body: Methods_1ctwem4['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ctwem4['post']['status']>(prefix, PATH11, POST, option).send(),
              /**
               * キャリアアドバイザー紹介求人詳細を閲覧済み登録を行う
               * @param option.body - キャリアアドバイザー紹介求人閲覧済み登録リクエストボディ
               */
              $post: (option: { body: Methods_1ctwem4['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1ctwem4['post']['status']>(prefix, PATH11, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH11}`,
            },
            related: {
              otherJobs: {
                /**
                 * 法人顧客IDに関連する求人情報(T原稿のみ)を取得する
                 * @returns 成功
                 */
                get: (option: { query: Methods_ou7n4l['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_ou7n4l['get']['resBody'], BasicHeaders, Methods_ou7n4l['get']['status']>(prefix, PATH12, GET, option).json(),
                /**
                 * 法人顧客IDに関連する求人情報(T原稿のみ)を取得する
                 * @returns 成功
                 */
                $get: (option: { query: Methods_ou7n4l['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_ou7n4l['get']['resBody'], BasicHeaders, Methods_ou7n4l['get']['status']>(prefix, PATH12, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_ou7n4l['get']['query'] } | undefined) =>
                  `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            },
            state: {
              /**
               * キャリアアドバイザー紹介求人の紹介状況などを取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_wqu3f2['get']['resBody'], BasicHeaders, Methods_wqu3f2['get']['status']>(prefix, PATH13, GET, option).json(),
              /**
               * キャリアアドバイザー紹介求人の紹介状況などを取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_wqu3f2['get']['resBody'], BasicHeaders, Methods_wqu3f2['get']['status']>(prefix, PATH13, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH13}`,
            },
            /**
             * キャリアアドバイザー紹介求人一覧を取得する
             * @returns 成功
             */
            get: (option?: { query?: Methods_1a4o39s['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1a4o39s['get']['resBody'], BasicHeaders, Methods_1a4o39s['get']['status']>(prefix, PATH7, GET, option).json(),
            /**
             * キャリアアドバイザー紹介求人一覧を取得する
             * @returns 成功
             */
            $get: (option?: { query?: Methods_1a4o39s['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1a4o39s['get']['resBody'], BasicHeaders, Methods_1a4o39s['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1a4o39s['get']['query'] } | undefined) =>
              `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
        },
        corporateClients: {
          _corporateClientId: (val3: number) => {
            const prefix3 = `${PATH14}/${val3}`;

            return {
              publicationPhotos: {
                /**
                 * 指定した法人顧客IDから企業の承認済み写真一覧を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_v7kqnq['get']['resBody'], BasicHeaders, Methods_v7kqnq['get']['status']>(prefix, `${prefix3}${PATH15}`, GET, option).json(),
                /**
                 * 指定した法人顧客IDから企業の承認済み写真一覧を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_v7kqnq['get']['resBody'], BasicHeaders, Methods_v7kqnq['get']['status']>(prefix, `${prefix3}${PATH15}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH15}`,
              },
            };
          },
        },
        counseling: {
          diagnosticquestionnaire: {
            answers: {
              status: {
                /**
                 * 診断アンケート(HRAnalyst)回答状況を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_12orw7k['get']['resBody'], BasicHeaders, Methods_12orw7k['get']['status']>(prefix, PATH16, GET, option).json(),
                /**
                 * 診断アンケート(HRAnalyst)回答状況を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_12orw7k['get']['resBody'], BasicHeaders, Methods_12orw7k['get']['status']>(prefix, PATH16, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${PATH16}`,
              },
            },
          },
        },
        events: {
          _entryId: (val3: string) => {
            const prefix3 = `${PATH17}/${val3}`;

            return {
              /**
               * 指定した個人顧客エントリーIDからイベント詳細情報を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1cgypke['get']['resBody'], BasicHeaders, Methods_1cgypke['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した個人顧客エントリーIDからイベント詳細情報を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1cgypke['get']['resBody'], BasicHeaders, Methods_1cgypke['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
        },
        featureFlags: {
          /**
           * 指定したパラメータを元にフューチャーフラグを取得する
           * @returns 成功
           */
          get: (option: { query: Methods_1ar46wa['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1ar46wa['get']['resBody'], BasicHeaders, Methods_1ar46wa['get']['status']>(prefix, PATH18, GET, option).json(),
          /**
           * 指定したパラメータを元にフューチャーフラグを取得する
           * @returns 成功
           */
          $get: (option: { query: Methods_1ar46wa['get']['query'], config?: T | undefined }) =>
            fetch<Methods_1ar46wa['get']['resBody'], BasicHeaders, Methods_1ar46wa['get']['status']>(prefix, PATH18, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1ar46wa['get']['query'] } | undefined) =>
            `${prefix}${PATH18}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        front: {
          job: {
            search: {
              canonical: {
                /**
                 * 求人検索画面のcanonicalURLの置換をする
                 * @returns 成功
                 */
                get: (option: { query: Methods_13lk0ru['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_13lk0ru['get']['resBody'], BasicHeaders, Methods_13lk0ru['get']['status']>(prefix, PATH19, GET, option).json(),
                /**
                 * 求人検索画面のcanonicalURLの置換をする
                 * @returns 成功
                 */
                $get: (option: { query: Methods_13lk0ru['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_13lk0ru['get']['resBody'], BasicHeaders, Methods_13lk0ru['get']['status']>(prefix, PATH19, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_13lk0ru['get']['query'] } | undefined) =>
                  `${prefix}${PATH19}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
              footerLinkPattern: {
                /**
                 * 下部リンクパターンの取得をする
                 * @returns 成功
                 */
                get: (option: { query: Methods_8ls0yn['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_8ls0yn['get']['resBody'], BasicHeaders, Methods_8ls0yn['get']['status']>(prefix, PATH20, GET, option).json(),
                /**
                 * 下部リンクパターンの取得をする
                 * @returns 成功
                 */
                $get: (option: { query: Methods_8ls0yn['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_8ls0yn['get']['resBody'], BasicHeaders, Methods_8ls0yn['get']['status']>(prefix, PATH20, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_8ls0yn['get']['query'] } | undefined) =>
                  `${prefix}${PATH20}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
              titlemeta: {
                /**
                 * SEO関連titleMeta情報の取得をする
                 * @returns 成功
                 */
                get: (option: { query: Methods_1nl9x3n['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1nl9x3n['get']['resBody'], BasicHeaders, Methods_1nl9x3n['get']['status']>(prefix, PATH21, GET, option).json(),
                /**
                 * SEO関連titleMeta情報の取得をする
                 * @returns 成功
                 */
                $get: (option: { query: Methods_1nl9x3n['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1nl9x3n['get']['resBody'], BasicHeaders, Methods_1nl9x3n['get']['status']>(prefix, PATH21, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_1nl9x3n['get']['query'] } | undefined) =>
                  `${prefix}${PATH21}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            },
          },
          jobs: {
            search: {
              label: {
                /**
                 * 指定した検索条件の表示用ラベル文字列を取得する
                 * @returns 成功
                 */
                get: (option?: { query?: Methods_6lsh3h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_6lsh3h['get']['resBody'], BasicHeaders, Methods_6lsh3h['get']['status']>(prefix, PATH22, GET, option).json(),
                /**
                 * 指定した検索条件の表示用ラベル文字列を取得する
                 * @returns 成功
                 */
                $get: (option?: { query?: Methods_6lsh3h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_6lsh3h['get']['resBody'], BasicHeaders, Methods_6lsh3h['get']['status']>(prefix, PATH22, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_6lsh3h['get']['query'] } | undefined) =>
                  `${prefix}${PATH22}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              },
            },
          },
        },
        image: {
          company: {
            /**
             * noImageを取得する
             * @returns 成功
             */
            get: (option?: { query?: Methods_mweons['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_mweons['get']['resBody'], BasicHeaders, Methods_mweons['get']['status']>(prefix, PATH23, GET, option).text(),
            /**
             * noImageを取得する
             * @returns 成功
             */
            $get: (option?: { query?: Methods_mweons['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_mweons['get']['resBody'], BasicHeaders, Methods_mweons['get']['status']>(prefix, PATH23, GET, option).text().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_mweons['get']['query'] } | undefined) =>
              `${prefix}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
        },
        industry: {
          /**
           * 検索用業種マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_h9ruy5['get']['resBody'], BasicHeaders, Methods_h9ruy5['get']['status']>(prefix, PATH24, GET, option).json(),
          /**
           * 検索用業種マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_h9ruy5['get']['resBody'], BasicHeaders, Methods_h9ruy5['get']['status']>(prefix, PATH24, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH24}`,
        },
        job: {
          _jid: (val3: string) => {
            const prefix3 = `${PATH25}/${val3}`;

            return {
              bookmark: {
                /**
                 * 気になるを登録する
                 * @param option.body - 気になる登録リクエストボディ
                 */
                post: (option: { body: Methods_1cgl7dt['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1cgl7dt['post']['status']>(prefix, `${prefix3}${PATH26}`, POST, option).send(),
                /**
                 * 気になるを登録する
                 * @param option.body - 気になる登録リクエストボディ
                 */
                $post: (option: { body: Methods_1cgl7dt['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1cgl7dt['post']['status']>(prefix, `${prefix3}${PATH26}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH26}`,
              },
              clicksCountUp: {
                /**
                 * 日別クリック数を更新する
                 * @param option.body - 日別クリック更新リクエストボディ
                 */
                post: (option: { body: Methods_tx49zq['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_tx49zq['post']['status']>(prefix, `${prefix3}${PATH27}`, POST, option).send(),
                /**
                 * 日別クリック数を更新する
                 * @param option.body - 日別クリック更新リクエストボディ
                 */
                $post: (option: { body: Methods_tx49zq['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_tx49zq['post']['status']>(prefix, `${prefix3}${PATH27}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH27}`,
              },
              markAsRead: {
                /**
                 * 指定した求人を閲覧済みとする
                 */
                post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_dxiw0c['post']['status']>(prefix, `${prefix3}${PATH28}`, POST, option).send(),
                /**
                 * 指定した求人を閲覧済みとする
                 */
                $post: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_dxiw0c['post']['status']>(prefix, `${prefix3}${PATH28}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH28}`,
              },
              pvCountUp: {
                /**
                 * 日別PV数を更新する
                 * @param option.body - 日別PV数更新リクエストボディ
                 */
                post: (option: { body: Methods_1ggb4dr['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1ggb4dr['post']['status']>(prefix, `${prefix3}${PATH29}`, POST, option).send(),
                /**
                 * 日別PV数を更新する
                 * @param option.body - 日別PV数更新リクエストボディ
                 */
                $post: (option: { body: Methods_1ggb4dr['post']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1ggb4dr['post']['status']>(prefix, `${prefix3}${PATH29}`, POST, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH29}`,
              },
              relationInfoList: {
                _corporateClientId: (val5: string) => {
                  const prefix5 = `${prefix3}${PATH30}/${val5}`;

                  return {
                    /**
                     * 指定した求人IDと法人顧客IDを基に、企業が募集中のその他求人情報を取得する
                     * @returns 成功
                     */
                    get: (option: { query: Methods_1njf7b1['get']['query'], config?: T | undefined }) =>
                      fetch<Methods_1njf7b1['get']['resBody'], BasicHeaders, Methods_1njf7b1['get']['status']>(prefix, prefix5, GET, option).json(),
                    /**
                     * 指定した求人IDと法人顧客IDを基に、企業が募集中のその他求人情報を取得する
                     * @returns 成功
                     */
                    $get: (option: { query: Methods_1njf7b1['get']['query'], config?: T | undefined }) =>
                      fetch<Methods_1njf7b1['get']['resBody'], BasicHeaders, Methods_1njf7b1['get']['status']>(prefix, prefix5, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get' | undefined; query: Methods_1njf7b1['get']['query'] } | undefined) =>
                      `${prefix}${prefix5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                  };
                },
              },
              /**
               * 指定した求人IDから求人の詳細情報を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1if69eo['get']['resBody'], BasicHeaders, Methods_1if69eo['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した求人IDから求人の詳細情報を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1if69eo['get']['resBody'], BasicHeaders, Methods_1if69eo['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
        },
        jobType: {
          /**
           * 職種マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1kgnldy['get']['resBody'], BasicHeaders, Methods_1kgnldy['get']['status']>(prefix, PATH31, GET, option).json(),
          /**
           * 職種マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1kgnldy['get']['resBody'], BasicHeaders, Methods_1kgnldy['get']['status']>(prefix, PATH31, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH31}`,
        },
        jobs: {
          corporateJobs: {
            /**
             * 指定した法人顧客IDに紐づく求人情報を取得する
             * @returns 成功
             */
            get: (option: { query: Methods_1h3q6m7['get']['query'], config?: T | undefined }) =>
              fetch<Methods_1h3q6m7['get']['resBody'], BasicHeaders, Methods_1h3q6m7['get']['status']>(prefix, PATH33, GET, option).json(),
            /**
             * 指定した法人顧客IDに紐づく求人情報を取得する
             * @returns 成功
             */
            $get: (option: { query: Methods_1h3q6m7['get']['query'], config?: T | undefined }) =>
              fetch<Methods_1h3q6m7['get']['resBody'], BasicHeaders, Methods_1h3q6m7['get']['status']>(prefix, PATH33, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1h3q6m7['get']['query'] } | undefined) =>
              `${prefix}${PATH33}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          count: {
            /**
             * 求人件数を取得する
             * @returns 成功
             */
            get: (option?: { query?: Methods_3quz6n['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_3quz6n['get']['resBody'], BasicHeaders, Methods_3quz6n['get']['status']>(prefix, PATH34, GET, option).json(),
            /**
             * 求人件数を取得する
             * @returns 成功
             */
            $get: (option?: { query?: Methods_3quz6n['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_3quz6n['get']['resBody'], BasicHeaders, Methods_3quz6n['get']['status']>(prefix, PATH34, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_3quz6n['get']['query'] } | undefined) =>
              `${prefix}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          featured: {
            /**
             * 今週の注目求人一覧を取得する
             * @returns 成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1hk2ini['get']['resBody'], BasicHeaders, Methods_1hk2ini['get']['status']>(prefix, PATH35, GET, option).json(),
            /**
             * 今週の注目求人一覧を取得する
             * @returns 成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1hk2ini['get']['resBody'], BasicHeaders, Methods_1hk2ini['get']['status']>(prefix, PATH35, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH35}`,
          },
          keywords: {
            /**
             * フリーワード検索時に類似キーワードを取得する
             * @returns 成功
             */
            get: (option: { query: Methods_ipxlww['get']['query'], config?: T | undefined }) =>
              fetch<Methods_ipxlww['get']['resBody'], BasicHeaders, Methods_ipxlww['get']['status']>(prefix, PATH36, GET, option).json(),
            /**
             * フリーワード検索時に類似キーワードを取得する
             * @returns 成功
             */
            $get: (option: { query: Methods_ipxlww['get']['query'], config?: T | undefined }) =>
              fetch<Methods_ipxlww['get']['resBody'], BasicHeaders, Methods_ipxlww['get']['status']>(prefix, PATH36, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_ipxlww['get']['query'] } | undefined) =>
              `${prefix}${PATH36}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          neararea: {
            /**
             * 指定した検索条件から近隣エリアを取得する
             * @returns 成功
             */
            get: (option?: { query?: Methods_1nt8tj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1nt8tj['get']['resBody'], BasicHeaders, Methods_1nt8tj['get']['status']>(prefix, PATH37, GET, option).json(),
            /**
             * 指定した検索条件から近隣エリアを取得する
             * @returns 成功
             */
            $get: (option?: { query?: Methods_1nt8tj['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1nt8tj['get']['resBody'], BasicHeaders, Methods_1nt8tj['get']['status']>(prefix, PATH37, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1nt8tj['get']['query'] } | undefined) =>
              `${prefix}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          recommendations: {
            applicationAndBookmark: {
              /**
               * 応募・気になる履歴に基づくレコメンド求人一覧を取得する
               * @returns 成功
               */
              get: (option: { query: Methods_ffa726['get']['query'], config?: T | undefined }) =>
                fetch<Methods_ffa726['get']['resBody'], BasicHeaders, Methods_ffa726['get']['status']>(prefix, PATH38, GET, option).json(),
              /**
               * 応募・気になる履歴に基づくレコメンド求人一覧を取得する
               * @returns 成功
               */
              $get: (option: { query: Methods_ffa726['get']['query'], config?: T | undefined }) =>
                fetch<Methods_ffa726['get']['resBody'], BasicHeaders, Methods_ffa726['get']['status']>(prefix, PATH38, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_ffa726['get']['query'] } | undefined) =>
                `${prefix}${PATH38}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            interests: {
              /**
               * ユーザー情報からユーザーの興味に近い求人一覧を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_o81vz4['get']['resBody'], BasicHeaders, Methods_o81vz4['get']['status']>(prefix, PATH39, GET, option).json(),
              /**
               * ユーザー情報からユーザーの興味に近い求人一覧を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_o81vz4['get']['resBody'], BasicHeaders, Methods_o81vz4['get']['status']>(prefix, PATH39, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH39}`,
            },
          },
          topics: {
            _id: (val4: string) => {
              const prefix4 = `${PATH40}/${val4}`;

              return {
                /**
                 * 指定した特集枠IDを基に特集枠情報および求人検索条件を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_16zdarc['get']['resBody'], BasicHeaders, Methods_16zdarc['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定した特集枠IDを基に特集枠情報および求人検索条件を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_16zdarc['get']['resBody'], BasicHeaders, Methods_16zdarc['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
          },
          /**
           * 指定した検索条件から求人一覧を取得する
           * @returns 成功
           */
          get: (option?: { query?: Methods_108luin['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_108luin['get']['resBody'], BasicHeaders, Methods_108luin['get']['status']>(prefix, PATH32, GET, option).json(),
          /**
           * 指定した検索条件から求人一覧を取得する
           * @returns 成功
           */
          $get: (option?: { query?: Methods_108luin['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_108luin['get']['resBody'], BasicHeaders, Methods_108luin['get']['status']>(prefix, PATH32, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_108luin['get']['query'] } | undefined) =>
            `${prefix}${PATH32}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        jwttest: {
          /**
           * テスト
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_13ju89s['get']['resBody'], BasicHeaders, Methods_13ju89s['get']['status']>(prefix, PATH41, GET, option).text(),
          /**
           * テスト
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_13ju89s['get']['resBody'], BasicHeaders, Methods_13ju89s['get']['status']>(prefix, PATH41, GET, option).text().then(r => r.body),
          $path: () => `${prefix}${PATH41}`,
        },
        mail: {
          unsubscribe: {
            /**
             * メール購読解除の登録をする
             */
            post: (option: { query: Methods_1ex2g6['post']['query'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_1ex2g6['post']['status']>(prefix, PATH42, POST, option).send(),
            /**
             * メール購読解除の登録をする
             */
            $post: (option: { query: Methods_1ex2g6['post']['query'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_1ex2g6['post']['status']>(prefix, PATH42, POST, option).send().then(r => r.body),
            $path: (option?: { method: 'post'; query: Methods_1ex2g6['post']['query'] } | undefined) =>
              `${prefix}${PATH42}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
        },
        prePublishedScout: {
          jobs: {
            _prePublishedScoutId: (val4: number) => {
              const prefix4 = `${PATH43}/${val4}`;

              return {
                /**
                 * 掲載前スカウトに紐づく求人詳細を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1aqc38a['get']['resBody'], BasicHeaders, Methods_1aqc38a['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 掲載前スカウトに紐づく求人詳細を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1aqc38a['get']['resBody'], BasicHeaders, Methods_1aqc38a['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix4}`,
              };
            },
            bookmark: {
              /**
               * 掲載前スカウト求人を気になる登録する
               * @param option.body - 掲載前スカウト求人気になる登録リクエストボディ
               */
              post: (option: { body: Methods_9h9im2['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_9h9im2['post']['status']>(prefix, PATH44, POST, option).send(),
              /**
               * 掲載前スカウト求人を気になる登録する
               * @param option.body - 掲載前スカウト求人気になる登録リクエストボディ
               */
              $post: (option: { body: Methods_9h9im2['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_9h9im2['post']['status']>(prefix, PATH44, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH44}`,
            },
          },
        },
        qualifications: {
          /**
           * 資格一覧情報を取得する
           * @returns 成功
           */
          get: (option?: { query?: Methods_c74r9t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_c74r9t['get']['resBody'], BasicHeaders, Methods_c74r9t['get']['status']>(prefix, PATH45, GET, option).json(),
          /**
           * 資格一覧情報を取得する
           * @returns 成功
           */
          $get: (option?: { query?: Methods_c74r9t['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_c74r9t['get']['resBody'], BasicHeaders, Methods_c74r9t['get']['status']>(prefix, PATH45, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_c74r9t['get']['query'] } | undefined) =>
            `${prefix}${PATH45}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        railwayCompany: {
          /**
           * 鉄道マスタの情報を取得する
           * @returns 成功
           */
          get: (option?: { query?: Methods_zee37p['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_zee37p['get']['resBody'], BasicHeaders, Methods_zee37p['get']['status']>(prefix, PATH46, GET, option).json(),
          /**
           * 鉄道マスタの情報を取得する
           * @returns 成功
           */
          $get: (option?: { query?: Methods_zee37p['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_zee37p['get']['resBody'], BasicHeaders, Methods_zee37p['get']['status']>(prefix, PATH46, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_zee37p['get']['query'] } | undefined) =>
            `${prefix}${PATH46}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        },
        savedSearches: {
          _no: (val3: string) => {
            const prefix3 = `${PATH47}/${val3}`;

            return {
              /**
               * 指定した検索条件を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_itimew['get']['resBody'], BasicHeaders, Methods_itimew['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した検索条件を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_itimew['get']['resBody'], BasicHeaders, Methods_itimew['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              /**
               * 指定した検索条件を更新する
               * @param option.body - 検索条件保存リクエストボディ
               */
              put: (option: { body: Methods_itimew['put']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_itimew['put']['status']>(prefix, prefix3, PUT, option).send(),
              /**
               * 指定した検索条件を更新する
               * @param option.body - 検索条件保存リクエストボディ
               */
              $put: (option: { body: Methods_itimew['put']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_itimew['put']['status']>(prefix, prefix3, PUT, option).send().then(r => r.body),
              /**
               * 指定した検索条件を削除する
               */
              delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_itimew['delete']['status']>(prefix, prefix3, DELETE, option).send(),
              /**
               * 指定した検索条件を削除する
               */
              $delete: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_itimew['delete']['status']>(prefix, prefix3, DELETE, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          /**
           * ユーザーに紐づく検索条件を全て取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1arbdhu['get']['resBody'], BasicHeaders, Methods_1arbdhu['get']['status']>(prefix, PATH47, GET, option).json(),
          /**
           * ユーザーに紐づく検索条件を全て取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_1arbdhu['get']['resBody'], BasicHeaders, Methods_1arbdhu['get']['status']>(prefix, PATH47, GET, option).json().then(r => r.body),
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          post: (option: { body: Methods_1arbdhu['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_1arbdhu['post']['status']>(prefix, PATH47, POST, option).send(),
          /**
           * 指定した検索条件を保存する
           * @param option.body - 検索条件保存リクエストボディ
           */
          $post: (option: { body: Methods_1arbdhu['post']['reqBody'], config?: T | undefined }) =>
            fetch<void, BasicHeaders, Methods_1arbdhu['post']['status']>(prefix, PATH47, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH47}`,
        },
        scout: {
          job: {
            _jid: (val4: string) => {
              const prefix4 = `${PATH48}/${val4}`;

              return {
                markAsRead: {
                  /**
                   * 指定した求人を閲覧済みとする。
                   * <br/>閲覧履歴の保存処理と閲覧履歴クッキーの付与を行う。
                   * <br/>リクエストパラメータにスカウトIDが存在し尚且つDR求人の場合は、専用の閲覧履歴の保存処理(スカウトメールの開封)を追加で行う。)
                   * @param option.body - スカウト求人リクエストボディ
                   */
                  post: (option: { body: Methods_1rp3slj['post']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1rp3slj['post']['status']>(prefix, `${prefix4}${PATH28}`, POST, option).send(),
                  /**
                   * 指定した求人を閲覧済みとする。
                   * <br/>閲覧履歴の保存処理と閲覧履歴クッキーの付与を行う。
                   * <br/>リクエストパラメータにスカウトIDが存在し尚且つDR求人の場合は、専用の閲覧履歴の保存処理(スカウトメールの開封)を追加で行う。)
                   * @param option.body - スカウト求人リクエストボディ
                   */
                  $post: (option: { body: Methods_1rp3slj['post']['reqBody'], config?: T | undefined }) =>
                    fetch<void, BasicHeaders, Methods_1rp3slj['post']['status']>(prefix, `${prefix4}${PATH28}`, POST, option).send().then(r => r.body),
                  $path: () => `${prefix}${prefix4}${PATH28}`,
                },
                /**
                 * 指定した求人ID/スカウトIDから求人の詳細情報を取得する
                 * @returns 成功
                 */
                get: (option?: { query?: Methods_jr3h7h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_jr3h7h['get']['resBody'], BasicHeaders, Methods_jr3h7h['get']['status']>(prefix, prefix4, GET, option).json(),
                /**
                 * 指定した求人ID/スカウトIDから求人の詳細情報を取得する
                 * @returns 成功
                 */
                $get: (option?: { query?: Methods_jr3h7h['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                  fetch<Methods_jr3h7h['get']['resBody'], BasicHeaders, Methods_jr3h7h['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_jr3h7h['get']['query'] } | undefined) =>
                  `${prefix}${prefix4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              };
            },
          },
        },
        selectJobs: {
          _selectJobCode: (val3: string) => {
            const prefix3 = `${PATH49}/${val3}`;

            return {
              reference: {
                /**
                 * 指定した特集コードに紐づくテーマが似ている求人特集一覧を取得する
                 * @returns 成功
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1en3614['get']['resBody'], BasicHeaders, Methods_1en3614['get']['status']>(prefix, `${prefix3}${PATH50}`, GET, option).json(),
                /**
                 * 指定した特集コードに紐づくテーマが似ている求人特集一覧を取得する
                 * @returns 成功
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1en3614['get']['resBody'], BasicHeaders, Methods_1en3614['get']['status']>(prefix, `${prefix3}${PATH50}`, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH50}`,
              },
              /**
               * 指定した特集コードから特集の詳細情報を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1y73qny['get']['resBody'], BasicHeaders, Methods_1y73qny['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * 指定した特集コードから特集の詳細情報を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1y73qny['get']['resBody'], BasicHeaders, Methods_1y73qny['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`,
            };
          },
          jobs: {
            count: {
              /**
               * 指定した検索条件で特集求人一覧件数を取得する
               * @param option.body - 特集求人一覧検索条件リクエストボディ
               * @returns 成功
               */
              post: (option: { body: Methods_xnzz7g['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_xnzz7g['post']['resBody'], BasicHeaders, Methods_xnzz7g['post']['status']>(prefix, PATH52, POST, option).json(),
              /**
               * 指定した検索条件で特集求人一覧件数を取得する
               * @param option.body - 特集求人一覧検索条件リクエストボディ
               * @returns 成功
               */
              $post: (option: { body: Methods_xnzz7g['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_xnzz7g['post']['resBody'], BasicHeaders, Methods_xnzz7g['post']['status']>(prefix, PATH52, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH52}`,
            },
            /**
             * 指定した検索条件で特集求人一覧を取得する
             * @param option.body - 特集求人一覧検索条件リクエストボディ
             * @returns 成功
             */
            post: (option: { body: Methods_1lob0jk['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1lob0jk['post']['resBody'], BasicHeaders, Methods_1lob0jk['post']['status']>(prefix, PATH51, POST, option).json(),
            /**
             * 指定した検索条件で特集求人一覧を取得する
             * @param option.body - 特集求人一覧検索条件リクエストボディ
             * @returns 成功
             */
            $post: (option: { body: Methods_1lob0jk['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1lob0jk['post']['resBody'], BasicHeaders, Methods_1lob0jk['post']['status']>(prefix, PATH51, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH51}`,
          },
        },
        user: {
          service: {
            usageStatus: {
              /**
               * ログインユーザーのサービス利用状況を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_kzqanu['get']['resBody'], BasicHeaders, Methods_kzqanu['get']['status']>(prefix, PATH53, GET, option).json(),
              /**
               * ログインユーザーのサービス利用状況を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_kzqanu['get']['resBody'], BasicHeaders, Methods_kzqanu['get']['status']>(prefix, PATH53, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${PATH53}`,
            },
          },
        },
        users: {
          integration: {
            dodax: {
              agent: {
                /**
                 * dodaX連携会員情報を更新する
                 * @param option.body - dodaX連携会員情報更新リクエストボディ
                 */
                patch: (option: { body: Methods_1ihierz['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1ihierz['patch']['status']>(prefix, PATH56, PATCH, option).send(),
                /**
                 * dodaX連携会員情報を更新する
                 * @param option.body - dodaX連携会員情報更新リクエストボディ
                 */
                $patch: (option: { body: Methods_1ihierz['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<void, BasicHeaders, Methods_1ihierz['patch']['status']>(prefix, PATH56, PATCH, option).send().then(r => r.body),
                $path: () => `${prefix}${PATH56}`,
              },
              /**
               * dodaXから連携された情報でdodaに会員登録する
               * @param option.body - dodaX連携会員登録リクエストボディ
               */
              post: (option: { body: Methods_1w5ssoh['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1w5ssoh['post']['status']>(prefix, PATH55, POST, option).send(),
              /**
               * dodaXから連携された情報でdodaに会員登録する
               * @param option.body - dodaX連携会員登録リクエストボディ
               */
              $post: (option: { body: Methods_1w5ssoh['post']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1w5ssoh['post']['status']>(prefix, PATH55, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH55}`,
            },
          },
          notification: {
            password: {
              /**
               * 顧客IDをもとにパスワード変更日とポップアップ表示日を取得する
               * @returns 成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_5gkqkr['get']['resBody'], BasicHeaders, Methods_5gkqkr['get']['status']>(prefix, PATH57, GET, option).json(),
              /**
               * 顧客IDをもとにパスワード変更日とポップアップ表示日を取得する
               * @returns 成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_5gkqkr['get']['resBody'], BasicHeaders, Methods_5gkqkr['get']['status']>(prefix, PATH57, GET, option).json().then(r => r.body),
              /**
               * 顧客IDをもとにポップアップ表示日を登録する
               */
              post: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_5gkqkr['post']['status']>(prefix, PATH57, POST, option).send(),
              /**
               * 顧客IDをもとにポップアップ表示日を登録する
               */
              $post: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_5gkqkr['post']['status']>(prefix, PATH57, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${PATH57}`,
            },
          },
          /**
           * ログインユーザーの会員情報を取得する
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_r5qu0t['get']['resBody'], BasicHeaders, Methods_r5qu0t['get']['status']>(prefix, PATH54, GET, option).json(),
          /**
           * ログインユーザーの会員情報を取得する
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_r5qu0t['get']['resBody'], BasicHeaders, Methods_r5qu0t['get']['status']>(prefix, PATH54, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH54}`,
        },
      },
      v2: {
        industry: {
          /**
           * 業種マスタ取得API。
           *
           * バージョン1では、検索用業種マスタのみを返却しているが、バージョン2では、 業種マスタと検索用業種マスタをセットで取得する。
           * @returns 成功
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_w9dtta['get']['resBody'], BasicHeaders, Methods_w9dtta['get']['status']>(prefix, PATH58, GET, option).json(),
          /**
           * 業種マスタ取得API。
           *
           * バージョン1では、検索用業種マスタのみを返却しているが、バージョン2では、 業種マスタと検索用業種マスタをセットで取得する。
           * @returns 成功
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_w9dtta['get']['resBody'], BasicHeaders, Methods_w9dtta['get']['status']>(prefix, PATH58, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH58}`,
        },
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
